// **********************************************************
// LAYOUT
.documentActions {
  margin-top: 7.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: $font-size-sm;
}

// **********************************************************
// DATE

// **********************************************************
// SHARE
.share-wrapper {
  margin-left: auto;
  margin-top: 1rem;

  &__title {
    margin: 1rem 0 .5rem 0;
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    text-align: center;
  }
  .socialShare__link {
    transition: $transition-base;
    width: 2.5rem;
    height: 2.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--#{$prefix}white);
    background-color: var(--#{$prefix}secondary);
    border-radius: 50%;
    font-size: 1.25rem; 
    & + .socialShare__link {
      margin-left: 1rem;
    }
    &:hover, &:focus {
      background-color: var(--#{$prefix}dark);
    }
  }
  // 768 pixels - 48em
  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}