// **********************************************************
// BIG BANNER
.bigBanner {
	&.ui.segment {
		background-image: linear-gradient(to bottom, var(--#{$prefix}white) 0%, var(--#{$prefix}white) 50%, var(--#{$prefix}primary) calc(50% + 1px), var(--#{$prefix}primary) 100%);
		margin-bottom: 0;
		padding-top: 0;
	}
	&__wrapper {
		transition: background-color .5s;
		background-color: var(--#{$prefix}background-03);
		color: var(--#{$prefix}body-color);
		border-radius: .5rem;
		padding:1.5rem;
		
	}
	&__title.ui.header { 
		margin-bottom: 2.5rem;
		font-size: 2.5rem;
	}
	&__desc {
		margin-bottom: 3rem;
	}
	&__img {
		// mix-blend-mode: hard-light;
		width: 100% !important;
		height: auto !important;
		margin-top: 3rem;
	}
	// 768 pixels - 48em
	@include media-breakpoint-up(md) {
		&__img {
			margin-top:0; 
			height: 100% !important;
			object-fit: contain;
			object-position: center center;
		}
	}

	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		&__wrapper {
			padding: 3.5rem 5rem 4rem 3.5rem;
		}
		&__title.ui.header {
			margin-top: 2rem;
		}
	}
}

// **********************************************************
// FOOTER
.portalFooter {
	--#{$prefix}footer-color: var(--#{$prefix}white-text, #{$white});
	--#{$prefix}link-color: var(--#{$prefix}white-text); 
	--#{$prefix}link-hover-color: var(--#{$prefix}secondary);
	
	&.ui.basic.segment{
		background-color: var(--#{$prefix}primary);
		color: var(--#{$prefix}footer-color);
		padding-top: 7.5rem; // 120px
		margin-bottom: 0;margin-top: 0;
		padding-bottom: 6rem;
	}
	a {
		text-decoration-color: var(--#{$prefix}link-color);
		transition: $transition-base;
	}
	p a {
		--#{$prefix}link-color: var(--#{$prefix}footer-color);
	}

// **********************************************************
// NEWSLETTER
	&__newsletter {
		&__intro {
			&.column {
				padding-bottom: 4rem;
			}
			.ui.header {
				font-weight: $font-weight-bold;
				color: var(--#{$prefix}footer-color);
				margin-bottom: 1.5rem;
			}
			p {
				color: var(--#{$prefix}tertiary);
				font-style: $font-size-base;
			}
		}
		&__form {
			&.ui.form {
				.fields {
					align-items: flex-end;
					margin-bottom: 2rem;
				}
				.field {
					label {
						color: var(--#{$prefix}footer-color) ;
						margin-bottom: 1.1em;
						font-size: $font-size-sm;
					}
					input {
						height: auto !important;
						padding: calc($btn-padding-y - 2px) $btn-padding-x;
						line-height: 1rem;
						font-size: $font-size-sm;
						border-radius: $btn-border-radius !important;
						border: 1px solid var(--#{$prefix}tertiary);
					}
					.ui.checkbox label{
						color: var(--#{$prefix}tertiary);
						font-size: $font-size-xs;
						margin-bottom: 0 !important;
					}
				}
			}
			
			@at-root .ui.form .fields > &__input.field {
				flex-grow: 1;
			}
			.ui.button {
				background-color: transparent;
				color: var(--#{$prefix}white-text);
				border: 1px solid currentColor;
				margin-right: 0;				
			}
		}

		// 768 pixels - 48em
		@include media-breakpoint-up(md) {
			&__intro {
				&.column {
					padding-bottom: 0;
				}
			}			
		}

		// 1280 pixels - 80em
		@include media-breakpoint-up(xl) {
			&__form {
				&.ui.form {
					.fields {
						padding-top: 2rem;
					}
				}
			}
		}
	}

	// required 
	.ui.form .required {
		&.fields:not(.grouped) > .field > label::after, 
		&.fields.grouped > label::after, 
		&.field > label::after, 
		&.fields:not(.grouped) > .field > .checkbox::after, 
		&.field > .checkbox::after {
			content:none;
		}
	}

	// **********************************************************
	// CERTIFICATES
	&__seals {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
		&__link {
			width: 5rem; height: auto;
			flex: 1 1 0;
			display: flex;
			justify-content: center;
			text-align: center;
		}
		&.ui.segment {
			margin: 5rem -1rem 7rem -1rem;
		}
		img.ui.image {			
			min-width: 5rem;
			max-width: 10rem;
			width: 5rem; height: auto;
			max-height:7.5rem;
			object-fit: contain;
			flex: 1 1 0;
		}
	}


	// **********************************************************
	// LOGO
	.portalLogo {		
		&__img {
			height: 3.5rem; 
			width: auto;
			display: block;
			margin-bottom: 2.5rem;
			& + p + p {
				margin-bottom: 2.5rem !important;
			}
		}
	}

	// **********************************************************
	// NAVIGATION
	&__nav {
		display: grid !important;
		grid-template-columns: 1fr;
		gap: 2.5rem 2rem;				
		&__title.ui.header {
			color: var(--#{$prefix}footer-color);
			font-size: $font-size-base;
			margin-bottom: 0;
			font-weight: $font-weight-bold;
		}
		&__list {
			display: flex;
			flex-direction: column;
			a {
				text-decoration: none;
			}
		}

		// 576 ixels - 36em
		@include media-breakpoint-up(lg) {
			grid-template-columns: 1fr auto;
			& > div{
				&:nth-child(1) {
					grid-row: 1 / 2;
					grid-column: 1/2;
				}
				&:nth-child(2) {
					grid-row: 2 / 3;
					grid-column: 1/2;
				}
				&:nth-child(3) {
					grid-row: 1 / 2;
					grid-column: 2/3;
				}
				&:nth-child(4) {
					grid-row: 2 / 3;
					grid-column: 2/3;
				}
			}
		}
		

		// XXSS
		&__xxss {
			a {
				display: inline-flex;
				width: 3rem;
				height: 3rem;
				border-radius: 50%;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				background-color: var(--#{$prefix}link-color);
				color: var(--#{$prefix}primary);
				font-size: 1.5rem;
				line-height: 1;
				&:hover, &:focus {
					background-color: var(--#{$prefix}link-hover-color);
				}
			}
			li + li {
				margin-left: 1rem;
			}
		}
	}

}
// **********************************************************
// BACK TO TOP BUTTON
.backToTop.ui.button {
	transition: $transition-base;
	background-color: rgba(var(--#{$prefix}gray-600-rgb), .25);
	color: var(--#{$prefix}white);
	font-size: 1.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	width: 3rem;
	height: 3rem;
	border: 2px solid rgba(var(--#{$prefix}gray-600-rgb), .5);
	position: fixed;
	right: 1rem;
	bottom: 1rem;
	z-index: $zindex-fixed;
	&.backToTop--hide {
		opacity: 0;
	}
	&.backToTop--show {
		opacity: .66;
	}
	&:hover, &:focus {
		opacity: 1;
	}
	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		right: 2rem;
		bottom: 2rem;
	}
	body.contactForm-template & {
		bottom: 6rem !important;
	}
}