// **********************************************************
// GREEN ICON
.greenIcon {
  transition: $transition-base;
  background-color: var(--#{$prefix}greenIcon-bg);
  color: var(--#{$prefix}greenIcon-color);
  font-size: var(--#{$prefix}greenIcon-fs);
  border-radius: var(--#{$prefix}greenIcon-radius);
  height:var(--#{$prefix}greenIcon-height);
  width:var(--#{$prefix}greenIcon-height);
  
  display: inline-flex;
  justify-content: center;
  align-items: center;	
  box-shadow: $box-shadow-sm;
  flex-shrink: 0;
  margin: 0;
  .svg-inline--fa {
    width: 1em;
    object-fit: contain;
  }
  @supports (aspect-ratio: 1 / 1) {
    height: auto;
    aspect-ratio: 1 / 1;
  }
}

// **********************************************************
// READ MORE LINK
.readMoreItem { 
  transition: $transition-base;
  font-size: $font-size-xs;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  text-underline-offset: .5rem;
  text-decoration-color: transparent;
  &__icon { 
    color: var(--#{$prefix}secondary, #{$secondary});
    margin-right: 1rem;
  }
}

// **********************************************************
// REMOVE INITIAL PADDING AND MARGIN WHEN NEEDED
body.contenttype-businessline .ui.basic.segment.content-area {
  padding-top: 0;
  margin-top: 0;
}
.ui.basic.segment.content-area {
  margin-bottom: 0;
  padding-bottom: 0;

}

// **********************************************************
// ICON SIZE
.svg-inline--fa {
	display: var(--fa-display, inline-block);
	height: 1em; 
	vertical-align: -0.125em;
}

// **********************************************************
// TEXT SELECTION
*::selection {
  background-color: #279DBA;
  color: var(--#{$prefix}white);
}