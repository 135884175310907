// **********************************************************
// VARIANTS
.offersList { 
  --#{$prefix}offers-text-color: var(--#{$prefix}body-color);
  
  &.bg-background-01 { 
    --#{$prefix}offers-text-color: var(--#{$prefix}white-text);
  }

// **********************************************************
// LAYOUT
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 8rem;
  &[class^='bg-background'], &[class*=' bg-background'] {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  &__title {
    &.ui.header {
      text-align: center;
      align-items: center;
      justify-content: center !important;
      color: var(--#{$prefix}offers-text-color);
      margin-bottom: 3rem;
      &.offersList__title--noDesc {
        margin-bottom: 4rem;
      }
    }
  }
  &__desc {
    color: var(--#{$prefix}offers-text-color);
    text-align: center;
    margin-bottom: 4rem !important;
  }
  &__cardGroup {
    justify-content: center;
    row-gap: 3rem;
  }
  .offersItem {
    margin-bottom: 3rem;
    @supports (row-gap: 1.5rem) {
      margin-bottom: 0;
    }
  }
  
}

// **********************************************************
// OFFER ITEM
.offersItem {
  &.ui.card {
    transition: $transition-base;
    border-radius: var(--#{$prefix}border-radius-lg);
    &:hover, &:focus-within {
      background-color: var(--#{$prefix}light);
      .offersItem__readmore.extra.content {
        color: var(--#{$prefix}link-hover-color);
      }
    }
  }
  &__title {
    &.header {
      padding: 2rem;
      font-size: $font-size-base;
      text-align: center;
      justify-content: center !important;
      align-items: center;
      font-weight: $font-weight-bold;
      margin-bottom: 0rem;
    }
  }
  &__link {
    color: var(--#{$prefix}body-color);
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: none;
    }
  }
  &__readmore {
    &.extra.content {
      border-top: none !important;
      padding-bottom: 2rem;
      padding-top: 1rem;
      color: var(--#{$prefix}body-color);
      text-align: center;
      text-decoration: underline;
      font-size: $font-size-base;
      margin-top: auto;
    }
  }
 

  // 768 pixels - 48em
  @include media-breakpoint-up(md) {
    height: 100%;
  }
}

// **********************************************************
// OFFER FORM
.offerForm {
  padding-top: 4.5rem;
  padding-bottom: 5.5rem;
  margin-bottom: 8rem;
  .Form__grid {
    display: grid;
    gap: 0 1.5rem;
    grid-template-columns: 1fr;
    // 992 pixels - 62em
    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 1fr;
      gap: 0 2.5rem;
      .field.textarea, .field:has(.file-widget-dropzone), #curriculumHelp, .field.field-wrapper-privacyPolicy {
        grid-column: 1 / 3;
      }
    }
  }
  .ui.segments {
    border: none;
    box-shadow: none;
    .segment {
      background-color: transparent;
    }
  }
  .ui.segment {
    border: none;
    box-shadow: none;
    background-color: transparent;
  }
  .ui.form .field {
    margin-bottom: 1.5rem;
    &:has(.file-widget-dropzone) {
      margin-bottom: 0;
    }
    .ui.basic.red.label {
      background-color: transparent !important;
    }
    .ui.grid .row {
      flex-direction: column !important;
      & > .column {
        width: 100% !important;
        padding-left: 0;
        padding-right: 0;
      }
    }
    &.inline .wrapper {
      min-height:0;
      & > label {
        font-size: $font-size-sm;
        color: var(--#{$prefix}primary);
        margin-bottom: 1rem;
      }
    }
    &.required .wrapper > label[for]::after {
      width: 0.5rem;
      height: 0.5rem;
      position: relative;
      top: -0.25rem;
    }    
    .ui.input input:not([type]), 
    .ui.input input[type="date"], 
    .ui.input input[type="datetime-local"], 
    .ui.input input[type="email"], 
    .ui.input input[type="number"], 
    .ui.input input[type="password"], 
    .ui.input input[type="search"], 
    .ui.input input[type="tel"], 
    .ui.input input[type="time"], 
    .ui.input input[type="text"], 
    .ui.input input[type="file"], 
    .ui.input input[type="url"], 
    textarea {
      border: 1px solid var(--#{$prefix}tertiary);
      border-radius: var(--#{$prefix}border-radius-sm);      
      padding-left: 1rem;
      font-size: $font-size-sm;
      &::placeholder {
        color: rgba( var(--#{$prefix}body-color-rgb), .8 );
      }
    }
    textarea {
      margin-top: 0;
      padding-top: 1.25rem;
    }
    &.inline.textarea{
      .wrapper {
        margin-top: 0;
      }
      textarea {
        min-height: 7rem;
      }
    }
    .file-widget-dropzone {
      display: flex;
      .dropzone-placeholder {
        margin-top: 0;
      }
      .label-file-widget-input {
        background-color: var(--#{$prefix}tertiary);
        color: var(--#{$prefix}body-color);
        border-radius: var(--#{$prefix}border-radius-sm);
        font-weight: $font-weight-normal;
        font-size: $font-size-sm;
        border: none;
        padding: 1rem 2rem;
        flex-shrink: 0;
        margin-left: 1.5rem;
        margin-top: 0;
        display: inline-flex;
        align-items: center;
        &:hover, &:focus {
          text-decoration: underline;
        }
      }
    }
  }
  button[type="submit"].ui.primary {
    background-color: var(--#{$prefix}primary) !important;
    color: var(--#{$prefix}white-text) !important;
    border-radius: var(--#{$prefix}border-radius-sm);
    margin-left: 0;
    padding: 1.5rem 2.5rem;
    border: none;
    &:hover, &:focus {
      background-color: var(--#{$prefix}primary) !important;
      color: var(--#{$prefix}white-text) !important;
      text-decoration: underline;
    }
    .visually-hidden {
      position: static !important;
      width: auto !important;
      height: auto !important;
      padding: 0 !important;
      margin: 0 !important;
      overflow: hidden !important;
      clip: none !important;
      white-space: nowrap !important;
      border: 0 !important;
    }
    .icon {
      display: none;
    }
    &.right {
      float: none;
      margin-right: auto;
    }
  }
  .actions {
    display: flex;
  }
  #curriculumHelp, #privacyText, #privacyPolicyLink {
    grid-column: 1 / -1;
    font-size: $font-size-xs;
    margin-bottom: 1.5rem;
  }
  #curriculumHelp {
    font-style: italic;
  }
}

// **********************************************************
// OFFER PAGE
.offersBody {
  &, &.ui.container {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}
.offersBody, .contenttype-offer .slate-editor {
  h2 {
    font-size: $h4-font-size;
  }
  h3 {
    font-size: $h5-font-size;
  }
}