// Set body properties with CSS custom variables
body {	 	
	color: var(--#{$prefix}body-color); 
	background-color: var(--#{$prefix}body-bg);
	font-size: $font-size-base;
}

// Links

a {
	color: var(--#{$prefix}link-color); 
	text-decoration: underline;
	text-decoration-color: var(--#{$prefix}link-hover-color);
	&:hover, &:focus {
		color:var(--#{$prefix}link-hover-color);
	  	text-decoration-color: var(--#{$prefix}link-hover-color);
	}
  }