$prefix: 'ecc-';

// **********************************************************
// SPACING
// scss-docs-start spacer-variables-maps
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3
);
// scss-docs-end spacer-variables-maps
// scss-docs-start position-map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%
);
// scss-docs-end position-map

$list-inline-padding:         .5rem;


// **********************************************************
// FONTS
$font-family-sans-serif:	  'Inter', sans-serif;
$font-family-body:          $font-family-sans-serif;

$font-weight-lighter:       lighter;
$font-weight-light:         300;
$font-weight-normal:        400;
$font-weight-semibold:      600;
$font-weight-bold:          700;
$font-weight-bolder:        bolder;

$font-size-base:			      1.125rem; // 18px
$h1-font-size:              $font-size-base * 3.6; // 61px
$h2-font-size:              $font-size-base * 2.5; // 45px
$h3-font-size:              $font-size-base * 2.3; // 41px
$h4-font-size:              $font-size-base * 1.5; // 27px
$h5-font-size:              $font-size-base * 1.22; // 22px
$h6-font-size:              $font-size-base; // 18px
// scss-docs-end font-variables

$font-size-sm:                1rem; //16px
$font-size-xs:                .875rem; //14px
$font-size-lg:                $font-size-base * 1.25;

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
);
// scss-docs-end font-sizes

$line-height-base:            1.4;
$line-height-sm:              1.25;
$line-height-lg:              2;

// **********************************************************
// HEADINGS

// scss-docs-start headings-variables
$headings-margin-bottom:	  $spacer * .5;
$headings-font-family:      $font-family-sans-serif;
$headings-font-style:       normal;
$headings-font-weight:      $font-weight-normal;
$headings-line-height:      1.1;
$headings-color:            var(--#{$prefix}primary);


// **********************************************************
// COLORS
$white:    		#fff;
$gray-100: 		#F6F5F5;
$gray-200: 		#e9ecef;
$gray-300: 		#dee2e6;
$gray-400: 		#ced4da;
$gray-500: 		#adb5bd;
$gray-600: 		#979797;
$gray-700: 		#555555;
$gray-800: 		#393939;
$gray-900: 		#212529;
$black:    		#000;


$primary:       #162626;
$secondary:     #7FB927;
$tertiary:      #C9DDDD;
$background01:  #4F5C5C;
$background02:  #F9F3E9;
$background03:  #E9F8CA;
$background04:  #CABEA9; 
$light:         $gray-100;
$dark:          $gray-900;

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "tertiary":   $tertiary,
  "background-01": $background01,
  "background-02": $background02,
  "background-03": $background03,
  "background-04": $background04,
  "light": $light,
  "dark": $dark,
  "white": $white,
  "gray-700": $gray-700,
  "gray-600": $gray-600,
  "gray-100": $gray-100
);

$body-color: 	  #3C3C3B;
$body-bg:       $white;

$text-muted:                  var(--#{$prefix}gray-600);

// Customize the light and dark text colors for use in our color contrast function.
$min-contrast-ratio:        4.5;
$color-contrast-dark:       $black;
$color-contrast-light:      $white;


// **********************************************************
// LINKS
//
// Style anchor elements.

$link-color:                              $primary;
$link-decoration:                         underline;
$link-shade-percentage:                   20%;
$link-hover-color:                        darken($secondary, 15%);
$link-hover-decoration:                   underline;

$stretched-link-pseudo-element:           after;
$stretched-link-z-index:                  1;

// **********************************************************
// GRID

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1600px
);

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1220px
);
// scss-docs-end container-max-widths

// **********************************************************
// COMPONENTS

// scss-docs-start border-variables
$border-width:                1px;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
);

$border-style:                solid;
$border-color:                $gray-300;
$border-color-translucent:    rgba($black, .175);
// scss-docs-end border-variables

// scss-docs-start box-shadow-variables
$box-shadow:                  0 .5rem 1rem rgba($black, .15);
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075);
$box-shadow-lg:               0 1rem 3rem rgba($black, .175);
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075);
// scss-docs-end box-shadow-variables

// scss-docs-start border-radius-variables
$border-radius:               .375rem;
$border-radius-sm:            .25rem;
$border-radius-lg:            .5rem;
$border-radius-xl:            1rem;
$border-radius-2xl:           2rem;
$border-radius-pill:          50rem;
// scss-docs-end border-radius-variables

$transition-base:             all .2s ease-in-out;
$transition-fade:             opacity .15s linear;

// **********************************************************
// OPTIONS

// Quickly modify global styling by enabling or disabling optional features.
$enable-caret:                true;
$enable-rounded:              true;
$enable-shadows:              false;
$enable-gradients:            false;
$enable-transitions:          true;
$enable-reduced-motion:       true;
$enable-smooth-scroll:        true;
$enable-grid-classes:         true;
$enable-container-classes:    true;
$enable-cssgrid:              false;
$enable-button-pointers:      true;
$enable-rfs:                  false;
$enable-validation-icons:     true;
$enable-negative-margins:     true;
$enable-deprecation-messages: true;
$enable-important-utilities:  true;


// **********************************************************
// BUTTONS

$btn-padding-y:               1.5em;
$btn-padding-x:               1.25em;
$btn-font-family:             $font-family-sans-serif;
$btn-font-size:               1rem;
$btn-line-height:             1.1;
$btn-white-space:             nowrap;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           .25rem;

$btn-hover-bg-shade-amount:       0%;
$btn-hover-bg-tint-amount:        0%;
$btn-hover-border-shade-amount:   0%;
$btn-hover-border-tint-amount:    0%;


// **********************************************************
// Z-INDEX

// scss-docs-start zindex-stack
$zindex-negative:             -1;
$zindex-flat:                 1;
$zindex-dropdown:             1000;
$zindex-sticky:               1020;
$zindex-fixed:                1030;
$zindex-offcanvas-backdrop:   1040;
$zindex-offcanvas:            1045;
$zindex-modal-backdrop:       1050;
$zindex-modal:                1055;
$zindex-popover:              1070;
$zindex-tooltip:              1080;
$zindex-toast:                1090;
// scss-docs-end zindex-stack


// **********************************************************
// TRANSITIONS
$transition-fast:             .25s;