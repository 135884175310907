.cardsListing {

  // **********************************************************
  // VARIANTS
  --#{$prefix}cardlisting-text-color: var(--#{$prefix}body-color); 

  &.bg-background-01 {
    --#{$prefix}cardlisting-text-color: var(--#{$prefix}white-text); 
  }

// **********************************************************
// LAYOUT
  margin-bottom: 8rem;
  &[class^='bg-background'], &[class*=' bg-background'] {
    padding-top: 8rem; padding-bottom: 8rem;
  }
  &__title {
    &.ui.header {
      text-align: center;
      justify-content: center;
      margin-bottom: 2.5rem;
      font-size: $h4-font-size;
      color: var(--#{$prefix}cardlisting-text-color);
      .ui.basic.segment & {
        justify-content: center;
      }
    }  
    &__noDesc.ui.header {
      text-align: center;
      justify-content: center;
      margin-bottom: 3.5rem;
    }
  }
  .ui.header + &__desc, &__desc {
    margin-bottom: 3.5rem;
    text-align: center;
    color: var(--#{$prefix}cardlisting-text-color);
  }
  &__cardGroup {
    justify-content: center;
    row-gap: 3.5rem;
  } 
}

// **********************************************************
// CARD ITEM
.cardsListingItem {
  &.ui.card {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
    background-color: var(--#{$prefix}white);
    border-radius: var(--#{$prefix}border-radius-lg);
    margin-bottom: 3.5rem;
    @supports (row-gap: 1.5rem) {
      margin-bottom: 0;
    }
    & > .content {
      padding: 2rem;
      display: grid;
      grid-template-columns: minmax(0, 1fr) auto;
      grid-template-rows: auto 1fr;
      gap: 0 2rem;
      body.bigFont & {
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }
    }
  }
  .ui.card > .content > &__title.header, &__title {
    margin-bottom: 0;
    font-size: $font-size-base;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  &__icon {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    color: var(--#{$prefix}secondary);
    font-size: 2.5rem;
  }
  &__desc {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    &, .ui.card &.meta {
      color: var(--#{$prefix}body-color);
    }
  }
  
  // 768 pixels - 48em
  @include media-breakpoint-up(md) {
    height: 100%;
  }
}