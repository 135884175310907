// **********************************************************
// FEATURED ITEM
.featuredItem {
  &.ui.segment {
    position: relative !important;
    color: var(--#{$prefix}body-color);
    border-radius: .5rem;
    overflow: hidden;
    background-color: var(--#{$prefix}background-02);
    padding: 0;
    margin-top: 0;
    margin-bottom: 8rem;
  }
  &__intro {
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-weight: $font-weight-bold;
    color: var(--#{$prefix}secondary);
  }
  &__text {
    padding: 3.5rem 4rem;
  }
  &__title {
    &.ui.header {
      font-size: clamp(1.5rem, 1vw , $h4-font-size);
      margin-bottom: 4rem;
      margin-top: 0;
    }
  }
  ul.ui.list.eccList {
    margin-bottom: 4rem;
  }
  &__imgWrapper {
    position: relative;
    margin: 0;
  }
  &__img {
    display: block;
    width: 100%;
    height: auto;
  }
  &__logo {
    &.ui.image {
      height: 4.5rem;
      width: auto;
      position: absolute;
      opacity: .85;
      z-index: $zindex-flat;
      bottom: 2rem;
      right: 2rem;
    }
  }

  // 992 pixels - 62em
  @include media-breakpoint-up(lg) {
    &.ui.segment {
      display: flex;
    }
    &__text {     
      width: 60%;
      flex: 1 1 auto;
    }
    &__imgWrapper {
      width: 40%;
      flex: 0 0 auto;
    }
    &__img {     
      height: 100%;
      object-fit: cover;
    }
    &__logo {
      bottom: auto;
      top: 2rem;
    }   
  }
}