// **********************************************************
// LAYOUT
.newsSection {
  --#{$prefix}news-text-color: var(--#{$prefix}body-color);

  margin-top: -#{$font-size-base};
  margin-bottom: 5.75rem;
  &[class^='bg-background'], &[class*=' bg-background'] {
    padding-top: 7rem;
    padding-bottom: 3.75rem;
  }
  &.bg-background-01 {
    --#{$prefix}news-text-color: var(--#{$prefix}white-text);
  }
  &__title{
    &, &.ui.header {
      margin-bottom: 5.5rem;
      color: var(--#{$prefix}news-text-color);
    }
  }
}

// **********************************************************
// NEWS ITEM
.newsItem {
  --#{$prefix}subject-color: #{$tertiary};
  &.ui.card {
    box-shadow: none;
    border-radius: 0; 
    height: 100%;
    background-color: transparent;
    color: var(--#{$prefix}body-color);
    & > .content {
      padding-left: 0;padding-right:0;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      border-top: none;
    }
  }
  .ui.card > &__img {
    &.image {
      border-radius: .5rem !important;
      overflow: hidden;
      box-shadow: none;
      background-color: transparent;
      img {
        display: block;
        transition: $transition-base;
        aspect-ratio: 4/3;
        object-fit: cover;
      }
    }
  }
  .meta {
    margin-bottom: 2rem;
    display: flex;
    align-items: baseline;
  }
  &__title {
    .ui.card > .content > &.header {
      text-wrap: balance;
      margin-top: 0;
      margin-bottom: 2.5rem;
      font-weight: $font-weight-normal;
      font-size: $font-size-base;
    }
  }
  &__link {
    text-decoration: none;
    color: var(--#{$prefix}news-text-color);
    &:hover, &:focus {
      text-decoration: none;
      color: var(--#{$prefix}news-text-color);
    }
  }
  .ui.card .meta &__date {
    font-size: $font-size-xs;
    color: var(--#{$prefix}news-text-color);
    margin-right: 1rem;
  }
  &__subject {
    &.ui.label {
      font-size: .625rem;
      border-radius: .5rem;
      text-transform: uppercase;
      font-weight: $font-weight-normal;
      background-color: var(--#{$prefix}subject-color);
    }
  }
  .readMoreItem{
    margin-top: auto;
    &__icon {
      transition: $transition-base;
    }
  }
  &:hover, &:focus-within {
    .newsItem__img img {
      transform: scale(1.1);
      transform-origin: center center;
    }
    .readMoreItem {
      text-decoration: underline;
      text-decoration-color: currentColor;
    }
  }
}

// **********************************************************
// NEWS LIST
.searchList {
  background-color: var(--#{$prefix}background-01);
  color: var(--#{$prefix}white);
  border-top: 1px solid var(--#{$prefix}tertiary);
  padding-top: 3.5rem;
  padding-bottom: 4rem;
  &.ui.form {
    .field > label {
      color: var(--#{$prefix}white);
    }
    .field > .selection.dropdown > .dropdown.icon {
      margin-right: 1rem;
      position: absolute;
      float: none;
      top: 50%;
    }
    .ui.input input[type="text"],
    .ui.selection.dropdown{
      border-radius: var(--#{$prefix}border-radius-sm);
      padding-left: 1rem;
      padding-right: 1rem;
      border-width: 1px;
    }
    .ui.input.date-input input[type="text"] {
      border: 0;
      width: 100%;
    }
    .ui.selection.dropdown,
    .ui.input.date-input .SingleDatePickerInput {
      padding-right: 0;
      width: 100%;
      border-radius: var(--#{$prefix}border-radius-sm);
      .DateInput {
        border-radius: var(--#{$prefix}border-radius-sm);
        width: 100%;
      }
    }
    .SingleDatePickerInput_clearDate {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
    body.bigFont & .equal.fields {
      flex-wrap: wrap;
      gap: 2rem;
    }
  }
  .ui.button{	
    min-width: 9.5rem;
  }
  .SingleDatePicker_picker {
    z-index: $zindex-dropdown;
  }
  .ui.input.focus > input,
  .ui.input > input:focus,
  .ui.input > input,
  &.ui.form * {
    &::placeholder {
      color: rgba(var(--#{$prefix}body-color-rgb), .8);
    }
  }
  .ui.dropdown:not(.button) > .default.text, .ui.default.dropdown:not(.button) > .text {
    color: rgba(var(--#{$prefix}body-color-rgb), .5);
  }
}
.newsListing {
  padding-top: 8rem;
  margin-bottom: 8rem;
}