// **********************************************************
// VARIANTS
.variant-iconlisting-01 {
	--#{$prefix}solution-fs-header: #{$h5-font-size};
	--#{$prefix}greenIcon-height: 3.5rem;
	--#{$prefix}greenIcon-fs: #{$h4-font-size};
	--#{$prefix}greenIcon-radius: var(--#{$prefix}border-radius-lg);
	--#{$prefix}iconList-header-fs: #{$h3-font-size};
	--#{$prefix}iconList-content-fs: #{$font-size-base};
}

.variant-iconlisting-02 {
	--#{$prefix}solution-fs-header: #{$h6-font-size};
	--#{$prefix}greenIcon-height: 2.5rem;
	--#{$prefix}greenIcon-fs: #{$font-size-base};
	--#{$prefix}greenIcon-radius: var(--#{$prefix}border-radius-sm);
	--#{$prefix}iconList-header-fs: #{$h4-font-size};
	--#{$prefix}iconList-content-fs: #{$font-size-sm};
}

// **********************************************************
// SERVICES
.iconItem {
	&.ui.card {
		color: var(--#{$prefix}solution-text-color, #{$body-color});
		background-color: transparent;
		border: none;
		box-shadow: none;
		& > .content {
			display: flex;
			padding: 0 0 calc(var(--#{$prefix}greenIcon-height, 3.5rem) * 0.7) 0;
			align-items: flex-start;
			font-size: var(--#{$prefix}iconList-content-fs);
			// Control increased font
			body.bigFont & {
				flex-wrap: wrap;
			}
			&.iconItem__heading {
				flex-grow: 0;
				padding: 1.5rem 1.5rem 1.5rem 1rem !important;
				background-color: var(--#{$prefix}gray-100);
				border-radius: var(--#{$prefix}border-radius-sm) !important;
				// 1600 pixels - 100em
				@include media-breakpoint-up(xxl) {
					padding: 1.5rem 1.6rem 1.5rem 1rem !important;					
				}
			}
			&.extra {
				color: var(--#{$prefix}solution-text-color, #{$body-color});
				padding-top: 1rem;
				border-top: none !important;
				flex-direction: column;
				align-items: flex-start;
				flex-grow: 1;
				justify-content: space-between;
				.readMoreItem {
					margin-top: auto;
				}
			}
		}

		// 768 pixels - 48em
		@include media-breakpoint-up(md) {
			height: 100%;
		}
	}
	
	&__icon {
		color: var(--#{$prefix}secondary);
		height: 3.5rem;
		margin-right: 1rem;
		font-size: 3.5rem;
		transform: translateY(.2em);
		&.svg-inline--fa {
			transform: none;
		}
	}
	&__header.header {
		margin-bottom: 0;
		margin-top: 0 !important;
		font-size: var(--#{$prefix}solution-fs-header, #{$h5-font-size}) !important;
		font-weight: #{$font-weight-bold} !important;
		text-wrap: balance;
		min-height: 2.5em;
	}
	&__link {
		text-decoration: none;
		color: var(--#{$prefix}solution-text-color);
		&:hover, &:focus {
			text-decoration: none;
		}
	}
	&__description {
		margin-bottom: 2rem !important;
	}	
	&:has(a):hover, &:has(a):focus-within {
		.greenIcon {
			color: var(--#{$prefix}white, #{$white});
			background-color: var(--#{$prefix}primary, #{$primary});
			width: calc(var(--#{$prefix}greenIcon-height) * 0.75);
		}
		.readMoreItem {
			text-decoration: underline;
		}
	}
}

// **********************************************************
// LIST ICON
.iconList {
	--#{$prefix}cardlisting-text-color: var(--#{$prefix}body-color); 
	--#{$prefix}solution-text-color:  var(--#{$prefix}body-color); 

	margin-bottom: 5.5rem;
  &.bg-background-01 {
    --#{$prefix}cardlisting-text-color: var(--#{$prefix}white-text); 
		--#{$prefix}solution-text-color:var(--#{$prefix}white-text);
		.iconItem.ui.card > .iconItem__heading.content {
			background-color: var(--#{$prefix}background-04);
			.iconItem__icon {
				color: var(--#{$prefix}gray-100);
			}
		}
  }
	&[class^='bg-background'], &[class*=' bg-background'] {
		padding-top: 8rem;
		margin-bottom: 8rem;
		padding-bottom: 4rem;	
	}
	&__title {
		&, &.ui.header {
			font-size: var(--#{$prefix}iconList-header-fs, #{$h4-font-size});
			margin-top: 0;
			color: var(--#{$prefix}cardlisting-text-color);
		}
		&.iconList__title--wDesc {
			margin-bottom: 1.25rem;
		}
		&.iconList__title--noDesc {
			margin-bottom: 4rem;
		}
	}
	&__desc {
		margin-bottom: 4.5rem;
		color: var(--#{$prefix}cardlisting-text-color);
	}
	&__img {
		margin-bottom: 5rem;
		border-radius: var(--#{$prefix}border-radius-lg);
	}
}

// **********************************************************
// List icon BIG ICONS
.iconList {
	&.variant-iconlisting-02 {
		&[class^='bg-background'], &[class*=' bg-background'] {
			padding-top: 5rem;
			padding-bottom: 6.5rem;
		}
		// Layout
		.iconList {
			&__title {
				&, &.ui.header {
					text-align: center;
					justify-content: center;
				}
				&.iconList__title--wDesc {
					margin-bottom: 2rem;
				}
				&.iconList__title--noDesc {
					margin-bottom: 5.5rem;
				}
			}
			&__desc {
				margin-bottom: 5.5rem;
				text-align: center;
			}
		}
		// Icon item
		.iconItem {
			&.ui.card > .content.iconItem__heading {
				background-color: transparent;				
				padding: 0 !important;
				border-radius: 0;
				flex-direction: column;
				align-items: center;
			}
			&__icon {
				height: 4.5rem;
				margin:0 auto 1rem auto;
				display: block;
				font-size: 4.5rem;				
				transform: none;
				line-height: 1em;
			}
			&__header.header {
				font-size: 4.75rem !important;
				font-weight: $font-weight-normal!important;
				text-align: center;
				color: var(--#{$prefix}body-color) !important;
				margin-bottom: 1rem;
				min-height: 0;
				margin-bottom: 0;
			}
			.extra.content {
				text-align: center;
				justify-content: center;
				padding-bottom: 0;
			}
			&__description {
				width: 100%;
				text-align: center;
			}
		}
	}
}
