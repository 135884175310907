// **********************************************************
// 1 - BODY
// 2 - HEADER
// 3 - LINKS
// 4 - BUTTONS
// 5 - HOME
// 6 - SLIDESHOW 
// 7 - NEWS 
// 8 - EVENTS
// 9 - SEARCH
// 10 - FORMS
// 11 - MODALS
// 12 - FOOTER
// 13 - LIST
// 14 - PAGE
// 15 - LABELS
// 16 - ICONS
// 17 - CARDS
// 18 - IMAGES 
// 19 - CLIENTS
// **********************************************************

body.highContrast {
    $contrastDark: #180059;
    $contrastLight: #FFFFFF;
    
    --#{$prefix}contrastDark:       #{$contrastDark};
    --#{$prefix}contrastLight:      #{$contrastLight};
    --#{$prefix}contrastDark-rgb:   24, 0, 89;
    --#{$prefix}contrastLight-rgb:  255, 255, 255;
    --#{$prefix}primary:            #{$contrastLight};
    --#{$prefix}primary-rgb:        --#{$prefix}contrastLight-rgb; 
    --#{$prefix}tertiary:           #{$contrastLight};   
    --#{$prefix}secondary:          #{$contrastLight};   
    --#{$prefix}black-rgb:          --#{$prefix}contrastLight-rgb;
	--#{$prefix}white:              #{$contrastDark};
	--#{$prefix}white-text:         #{$contrastLight};
	--#{$prefix}white-rgb:          var(--#{$prefix}contrastDark-rgb);
    --#{$prefix}light:              #{$contrastDark};
    --#{$prefix}dark-primary:       #{$contrastDark};
    --#{$prefix}white-text:         #{$contrastLight};
    --#{$prefix}background-01:      #{$contrastDark};
    --#{$prefix}background-02:      #{$contrastDark};
    --#{$prefix}background-03:      #{$contrastDark};
    --#{$prefix}background-01-rgb:  var(--#{$prefix}contrastDark-rgb);
    --#{$prefix}background-02-rgb:  var(--#{$prefix}contrastDark-rgb);
    --#{$prefix}background-03-rgb:  var(--#{$prefix}contrastDark-rgb);
    --#{$prefix}gray-100:          #{$contrastDark};

    --#{$prefix}facebook:           #{$contrastDark};
    --#{$prefix}twitter:            #{$contrastDark};
    --#{$prefix}whatsapp:           #{$contrastDark};
    --#{$prefix}linkedin:           #{$contrastDark};

    --#{$prefix}text-opacity: 1 !important; 

    --#{$prefix}type-bg: #{$contrastDark} !important;
    --#{$prefix}badge-color: #{$contrastLight} !important;

    --#{$prefix}directActions-bg:  #{$contrastDark};

    --#{$prefix}error-color: rgb(255,41,41);

    * {
        background-image: none !important;
    }
    @each $color, $value in $theme-colors {
        .bg-#{$color} {
            background-color: var(--#{$prefix}contrastDark) !important;
        }
        .text-bg-#{$color}, .btn-#{$color}, .btn-outline-#{$color} {
            background-color: var(--#{$prefix}contrastDark) !important;
            color:var(--#{$prefix}contrastLight) !important;
            border-color: var(--#{$prefix}contrastLight) !important;
        }
    }
    .bg-white {
        background-color: var(--#{$prefix}contrastDark) !important; 
    }
    .text-bg-white, .btn-white, .btn-outline-white {
        background-color: var(--#{$prefix}contrastDark) !important;
        color:var(--#{$prefix}contrastLight) !important;
        border-color: var(--#{$prefix}contrastLight) !important;
    }
    .border-white {
        --#{$prefix}white-rgb: var(--#{$prefix}contrastLight-rgb);
    }
    .opacity-50 { opacity: 1 !important;}

    .tabsItem__text__expand { background-color:  var(--#{$prefix}contrastDark);}

    // **********************************************************
    // 1 - BODY
    --#{$prefix}body-bg: #{$contrastDark};
    --#{$prefix}body-color: #{$contrastLight};
    --#{$prefix}body-color-rgb: var(--#{$prefix}contrastLight-rgb);

    
    // **********************************************************
    // 2 - HEADER
    --#{$prefix}bgMenu: #{$contrastDark};
    .portalHeader{
        --#{$prefix}white: #{$contrastLight};
        --#{$prefix}white-rgb: --#{$prefix}contrastLight-rgb;
        --#{$prefix}bgHeader: #{$contrastDark};
    }
    

    .directActions {
        --#{$prefix}btn-color: #{$contrastLight};
        --#{$prefix}link-color: #{$contrastLight};
        --#{$prefix}directActions-color: #{$contrastLight};
        --#{$prefix}directActions-bg:  #{$contrastDark};
        &__btn.ui.button.constrastToggle {
            background-color: var(--#{$prefix}contrastLight) !important;
            color: var(--#{$prefix}contrastDark) !important;
        }
    }
    .mnPrincipal {
        .desktop-menu-nav.ui.menu .item {
            --#{$prefix}link-hover-color:#{$contrastLight};
            --#{$prefix}nav-link-hover-color: #{$contrastLight};
        }
        .mobile-menu .ui {        
            --#{$prefix}nav-link-color:#{$contrastDark};
            --#{$prefix}nav-link-hover-color: #{$contrastDark};
            --#{$prefix}link-hover-color:#{$contrastDark};
            --#{$prefix}bgMenu: #{$contrastLight};
        }
    }
    .hamburger-wrapper .hamburger.is-active {
        --sa-hamburger-color: #{$contrastDark};
    }
    .innerHeader__wrapper {
        --#{$prefix}header-text-color2: #{$contrastDark};
    }
    .ui.cards > .card > .content > .header, .ui.card > .content > .header {
        color: var(--#{$prefix}contrastLight);
    }


    // **********************************************************
    // 3 - LINKS
    --#{$prefix}link-color: #{$contrastLight};
    --#{$prefix}link-hover-color: #{$contrastLight};
    .link-primary {
        color: var(--#{$prefix}link-color) !important;
        &:hover, &:focus {
            color: var(--#{$prefix}link-color) !important;
        }
    }

    // **********************************************************
    // 4 - BUTTONS
    .ui.button {
        background-color: $contrastDark !important;
        color: $contrastLight !important;
        &:hover, &:focus {
            color: $contrastLight !important;
        }
    }
    .ui.basic.buttons .button, 
    .ui.basic.button {
        color: $contrastLight !important;
        &:hover, &:focus {
            color: $contrastDark !important;
        }
    }
    .ui.primary.button {
        --ecc-white-text: #{$contrastDark};
    }
    #contact-form button[type="submit"].ui.primary {
        background-color: var(--#{$prefix}contrastLight) !important;
        color: var(--#{$prefix}contrastDark) !important;
    }

    // **********************************************************
    // 5 - HOME
    .slideItem {
        --#{$prefix}slideItem-color: #{$contrastLight};
        --#{$prefix}slideItem-bg:  #{$contrastDark};
        --#{$prefix}slideItem-bg-rgb: var(--#{$prefix}contrastDark-rgb);
    }
    .bannersHome {
        background-image: none;
        &__item {
            background-image: none;
            background-color: var(--#{$prefix}contrastDark);
            &-profiles__link {
                backdrop-filter: none;
                -webkit-backdrop-filter: none;
                background-color: var(--#{$prefix}contrastDark);
                &:focus, &:hover {
                    color: var(--#{$prefix}contrastLight);
                }
            }
            &::before{
                content: none !important;
            }
            &-profiles__link {
                backdrop-filter: none !important;
                border: 1px solid var(--#{$prefix}contrastDark);
                
                background-color: var(--#{$prefix}contrastLight) !important;
            }
        }
    }  

    
    // **********************************************************
    // 6 - SLIDESHOW 
    --#{$prefix}slideButtonBg:		transparent;
	--#{$prefix}slideButtonColor:  	var(--#{$prefix}--#{$prefix}contrastDark);
	--#{$prefix}slideButtonBg-hover:transparent;
	--#{$prefix}slideButtonColor-hover:var(--#{$prefix}contrastDark);    

    .slideItem {
        --#{$prefix}white: #{$contrastLight};
	    --#{$prefix}white-rgb: var(--#{$prefix}contrastLight-rgb);
    } 
    .slider__dot {
        --#{$prefix}dotColor: var(--#{$prefix}contrastLight); 
    }

    
    // **********************************************************
    // 7 - NEWS 
    .wrapperNewsEvents{
        background-image: none !important;
        &::after {
            content: none;
        }
    }
    .announcements {
        background-color: transparent;
        box-shadow: none;
    }
    .newsItem {
        &__date {
            opacity: 1;
        }
        &__type .ui.label {
            --#{$prefix}type-bg: #{$contrastDark} !important;
            --#{$prefix}badge-color: #{$contrastLight} !important;
        }
    }

    
    // **********************************************************
    // 8 - EVENTS
    .events {
        .text-white { 
            color: rgba(var(--#{$prefix}contrastLight-rgb), var(--sa-text-opacity)) !important;
        }
    }
    .eventItem {
        background-color: var(--#{$prefix}contrastDark);
        &__img {
            position: relative;
        }
        &__text{
            aspect-ratio: unset;
        }
        &-hasImage {
            --#{$prefix}event-color: $contrastLight;
            --#{$prefix}primary: #{$contrastDark};
        }
    }
    .wrapperNewsEvents__search {
        --#{$prefix}gray-100: #{$contrastDark};
        --#{$prefix}light-rgb: #{$contrastDark};
        background-image: none;
    }
    .calendarWrapper {
        .rbc-event {
            border: 1px solid $contrastDark;
            a {
                color: $contrastDark;
            }
        }
        .rbc-today {
            background-color: $contrastLight;
        }
        .rbc-date-cell.rbc-current button {
            color: $contrastDark;
        }
    }
    .searchList .DateInput input[type="text"] {
        border: 1px solid $contrastLight;
    }

    // **********************************************************
    // 9 - SEARCH
    .modalSearch .modal-dialog {
        background-color: var(--#{$prefix}contrastDark);
        background-image: none;
    }

    // **********************************************************
    // 10 - FORMS
    .form-control{
        background-color: var(--#{$prefix}contrastDark);
        &::placeholder {
            color: #{$contrastLight};
        }
    }
    .form-select {
        background-color: var(--#{$prefix}contrastDark);
        color: #{$contrastLight};
    }
    .ui.checkbox  label {
        &:hover, &:focus {
            color: var(--#{$prefix}contrastLight);
        }
    }
    .offerForm .ui.form .field .file-widget-dropzone .label-file-widget-input {
        background-color: transparent;
        border: 1px solid currentColor;
    }


    // **********************************************************
    // 11 - MODALS
    .btn-close {
        color: var(--#{$prefix}contrastLight);
        opacity: 1; 
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;           
    }




    // **********************************************************
    // 12 - FOOTER
    .portalFooter {
        --#{$prefix}link-color: var(--#{$prefix}contrastLight);
        --#{$prefix}link-hover-color: var(--#{$prefix}contrastLight);
        --#{$prefix}footer-color: var(--#{$prefix}contrastLight);
        &.ui.segment.basic {
            background-color: var(--#{$prefix}contrastDark);
        }
		.text-white {
			color: var(--#{$prefix}contrastLight) !important;
		}
        &__nav__xxss { 
            a {
                background-color: transparent;
                border: 1px solid var(--#{$prefix}contrastLight);
            }
        }
        &__seals img.ui.image {
            mix-blend-mode: lighten;
        }
    }
    .patros__link img{ 
        filter: invert(1)
    }

    // il·lustracions footer
	--#{$prefix}green-illustration: var(--#{$prefix}contrastDark);
	--#{$prefix}gray-illustration: #798576;
	--#{$prefix}black-illustration: var(--#{$prefix}contrastLight); 
	--#{$prefix}gray2-illustration: #f8f8f8;
	--#{$prefix}gray3-illustration: #6a7567;

    // **********************************************************
    // 13 - LIST
    .searchList {
        .field .ui.input input[type="text"], .field .ui.selection.dropdown {
            border: 1px solid $contrastLight;
            color: $contrastLight;
        }
        &.ui.form .field > label {
            color: $contrastLight;
        }
    }
    ul.list.ui > .eccList__item::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M10,20A10,10,0,1,1,20,10,10.011,10.011,0,0,1,10,20ZM6.5,8.894A1,1,0,0,0,5.71,10.51L8.15,13.62a1,1,0,0,0,1.579-.01l4.57-6V7.58A1.006,1.006,0,0,0,12.7,6.36l-3.78,5L7.29,9.28A1.008,1.008,0,0,0,6.5,8.894Z' fill='%23FFFFFF'/%3E%3C/svg%3E ");
    }

    // **********************************************************
    // 14 - PAGE
    .relatedContent {
        --#{$prefix}related-bg-color: #{$contrastDark};
        --#{$prefix}related-text-color: #{$contrastLight};
        --#{$prefix}related-link-color: #{$contrastLight};
        --#{$prefix}related-icon-color: #{$contrastDark};
        --#{$prefix}link-color: #{$contrastLight};
        --#{$prefix}link-hover-color: #{$contrastLight}; 
    }
    .bg-working {
        background-color: var(--#{$prefix}contrastDark);
    }
    .instrumentMenu {
        --#{$prefix}white: $contrastLight;
    }

      
    // **********************************************************
    // 15 - LABELS
    .ui.label {
        background-color: $contrastLight;
        color: $contrastDark;
    }

    
    // **********************************************************
    // 16 - ICONS
    --#{$prefix}greenIcon-bg: var(--#{$prefix}contrastDark) !important;
    --#{$prefix}greenIcon-color: var(--#{$prefix}contrastLight) !important;

    
    // **********************************************************
    // 17 - CARDS
    .ui.cards > .card, .ui.card {
        background-color: var(--#{$prefix}contrastDark);
    }

    
    // **********************************************************
    // 18 - IMAGES
    .ui.image {
        filter: brightness(.8) contrast(1.2);
    }


    // **********************************************************
    // 19 - CLIENTS
    .clients.bg-background-02 {
        .ui.image {
            filter: invert(100%) brightness(200%);
        }
    }
}
