// **********************************************************
// PAGINATION
.pagination {
  .ui.attached.menu{
    justify-content: center;
    &:not(.tabular) {
      border: none;
    }
  }
  .ui.secondary.menu {
    .item {
      color: var(--#{$prefix}primary);
      &.active {
        font-weight: $font-weight-bold;
        color: var(--#{$prefix}body-color);
      }
    }
  }
}