// **********************************************************
// LAYOUT 
.tabListing {
  --#{$prefix}background-tab-color: var(--#{$prefix}white-rgb, #{$white});
  --#{$prefix}border-tab-color: #{$gray-500};
  --#{$prefix}text-tab-color: var(--#{$prefix}body-color, #{$body-color});
  --#{$prefix}header-tab-color: var(--#{$prefix}body-color, #{$primary});
  --#{$prefix}link-hover-tab-color: var(--#{$prefix}link-hover-color);

  color: var(--#{$prefix}text-tab-color);
  margin-bottom: 8rem;
  padding: 0;
  &[class^='bg-background'], &[class*=' bg-background'] {
    padding-top: 8rem;
    padding-bottom: 8rem;
  } 
  &__title {
    &.ui.header, & {
      margin: 0 0 2rem 0;
      font-size: $h4-font-size;
      color: var(--#{$prefix}header-tab-color);
    }
    &--noDesc {
      margin-bottom: 7rem;
    }
  }
  &__desc {
    margin-bottom: 6rem;
    color: var(--#{$prefix}text-tab-color);
  }
  &.bg-background-01 {
    --#{$prefix}background-tab-color: var(--#{$prefix}background-01-rgb);
    --#{$prefix}border-tab-color: var(--#{$prefix}secondary);
    --#{$prefix}text-tab-color: var(--#{$prefix}white-text);
    --#{$prefix}header-tab-color: var(--#{$prefix}tertiary);
    --#{$prefix}link-hover-tab-color: var(--#{$prefix}tertiary);
  }
  &.bg-background-02 {
    --#{$prefix}background-tab-color: var(--#{$prefix}background-02-rgb);
    --#{$prefix}border-tab-color: var(--#{$prefix}tertiary);
    --#{$prefix}text-tab-color: var(--#{$prefix}body-color);
    --#{$prefix}header-tab-color: var(--#{$prefix}primary);
  }
  &.bg-background-03 {
    --#{$prefix}background-tab-color: var(--#{$prefix}background-03-rgb);
    --#{$prefix}border-tab-color: var(--#{$prefix}tertiary);
  }
  &.bg-background-04 {
    --#{$prefix}background-tab-color: var(--#{$prefix}background-04-rgb);
  }

  // 1280 pixels - 80em
  @include media-breakpoint-up(xl) {
    &__title,
    &__desc {
      max-width: 66%;
    }
  }

  // Mode edició
  &.editMode {
    .tabsItem__text__expand {
      display: none !important;
    }
    .tabsItem__text {
      max-height: none !important;
    }
  }
}

// **********************************************************
// TABS
.eccTabs {
  display: flex;
  max-width: 66rem; // 1200px
  margin: 0 auto;
  align-items: flex-start;
  &__menu {
    width: 25%;
    flex: 0 0 auto;
    display: none; 
    flex-direction: column;
    margin-right: 1rem;
    border-right: 1px solid var(--#{$prefix}border-tab-color);
    padding-top: 1.5rem;
  }
  &__menuItem {
    &.ui.button {
      text-align: right;
      font-size: $font-size-xs;
      transition: $transition-base;
      margin-bottom: 1.25em;
      text-decoration: none;
      color: var(--#{$prefix}text-tab-color) !important;
      padding-right: 1rem;
      border-right: .5rem solid transparent;
      line-height: 1.25;
      box-shadow: none !important;
      border-radius: 0;
      margin-right: 0;
      &.selected {
        border-color: var(--#{$prefix}border-tab-color);
      }
      &:hover, &:focus {
        text-decoration: none;
        border-color: currentColor;
        background-color: transparent !important;
        color: var(--#{$prefix}link-hover-tab-color) !important;
      }
    }
  }
  &__content {
    flex: 1 1 auto;
  }

  // Perque la gent es marrana treballant
  #main &__menuItem.ui.button.basic {
    box-shadow: none !important; 
  }
  
  // 768 pixels - 48em
  @include media-breakpoint-up(md) {
    &__menu {
      display: flex;
      min-height: 29rem;
    }
  }

  // 992 pixels - 62em
  @include media-breakpoint-up(lg) {
    &__menu { 
      margin-right: 4.5rem;
      padding-bottom: 3.5rem;
    }
    &__menuItem {
      &.ui.button {
        font-size: $font-size-sm;
      }
    }
  }

  // 1280 pixels - 80em
  @include media-breakpoint-up(xl) {
    &__menuItem {
      &.ui.button {
        font-size: $font-size-base;
        padding-right: 3rem;
      }
    }
  }
}

// **********************************************************
// TAB ITEM
.tabsItem {
  --#{$prefix}greenIcon-fs: 1rem;
  --#{$prefix}greenIcon-radius: .25rem;
  --#{$prefix}greenIcon-height: 2rem;
  $tabMinHeight: 19rem;
  display: block;
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;

  &__header {
    display: flex; 
    align-items: center;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--#{$prefix}gray-600);
    .greenIcon {
      margin-right: 1rem;
    }
    &:hover, &:focus-within {
      .tabsItem__readmore {
        text-decoration: underline;
      }
    }
  }
  &__title {
    &.header.ui {
      font-size: $h5-font-size;
      margin: 0 1em 0 0;
      color: var(--#{$prefix}header-tab-color);
    }
  }
  &__link {
    color: var(--#{$prefix}header-tab-color);
    text-decoration: none;
    &:hover, &:focus {
      color: var(--#{$prefix}header-tab-color);
      text-decoration: none;
    }
    &:focus {
      text-decoration: underline;
    }
  }
  &__readmore {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    font-size: $font-size-xs; 
    &__icon {
      font-size: 1.33em;
      color: var(--#{$prefix}secondary);
      margin-right: 1rem;
    }
  }
  &__text {
    transition: $transition-base;
    max-height: $tabMinHeight; 
    overflow: hidden;
    position: relative;
    color: var(--#{$prefix}text-tab-color);   
    &--expanded {
      max-height: 190rem;
      height: auto; 
      .tabsItem__text__expand {
        display: none !important;
      }
    }
    &__expand {
      height: 3rem;
      display: flex;
      position: absolute;
      inset: auto 0 0 0;
      background: linear-gradient( to bottom, rgba(var(--#{$prefix}background-tab-color), 0.8) 0%, rgb(var(--#{$prefix}background-tab-color)) 100%);
      align-items: flex-end;
      z-index: $zindex-dropdown;
      &__btn {
        &.ui.button.basic {
          font-size: $font-size-xs;
          color: var(--#{$prefix}text-tab-color) !important;
          padding: .5rem 0;
          &:hover, &:focus {
            background-color: transparent !important;
            --#{$prefix}text-tab-color: var(--#{$prefix}header-tab-color);
            .tabsItem__text__expand__btn__icon {
              transform: translateY(.25rem);
              color: var(--#{$prefix}link-hover-color);
            }
          }
        }
        &__icon {
          transition: $transition-base;
          color: var(--#{$prefix}text-tab-color);
          font-size: 1.25em;
          margin-right: .5rem;
          opacity: .8;
        }
      }
    }
  }

  // 768 pixels - 48em
  @include media-breakpoint-up(md) {
    display: none;
    margin-bottom: 0;
    &.visible {
      display: block;
    }
    &__text {
      max-height: calc( var(--#{$prefix}tab-height-min, #{$tabMinHeight}) - 8.5rem );
      &--expanded {
        max-height: 190rem;
      }
    }
  }

  // 992 pixels - 62em
  @include media-breakpoint-up(lg) {
    --#{$prefix}greenIcon-fs: 1.75rem;
    --#{$prefix}greenIcon-radius: .25rem;
    --#{$prefix}greenIcon-height: 3.5rem;
    &__header {
      flex-wrap: nowrap;
    }
    &__title {
      &.header.ui {
        font-size: $h5-font-size;
      }
    }
    &__readmore {
      margin-top: 0;
    }
  }
}