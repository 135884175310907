.block.teaser {
  padding: 1rem 0;
  margin: 1rem 0;
  fieldset.invisible & {
    margin: 0;
    padding: 0;
    
  }
  a {
    text-decoration: none;
  }
  .image-wrapper img {
    border-radius: var(--#{$prefix}border-radius-lg);
  }
  .styled.left {
    float: none;
  }
}
.styled-teaser.styled.left {
  float: none;
}