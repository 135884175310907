@media screen { 

// **********************************************************
/* ICON SELECTOR */
.icon-selector {
	h3.name {
		font-size: $h6-font-size;
	}
}


// **********************************************************
// SEARCH
.block.search .search-details {
	color: rgba(var(--#{$prefix}body-bg-rgb), .8);
	body.highContrast & {
		color: var(--#{$prefix}contrastLight);
	}
}


// **********************************************************
// SITE ADMIN
#field-site_logo-image {
	background-color: var(--#{$prefix}primary);
}
.ui.form .field .wrapper > label {
	line-height: $headings-line-height;
}
#sidebar .ui.segment { font-size: .75rem;}


// **********************************************************
// ADD NEW VOLTO ITEM 
div[data-popper-placement] {
	z-index: $zindex-fixed + 1;
}
.blocks-chooser {
	width: 26rem;
	.ui.button {
		&:hover, &:focus {
			text-decoration: none;
		}
	}
}


// **********************************************************
// DOCUMENT FIRST HEADING
.documentFirstHeading{
	border-bottom: none;
	&::before {
		content: none;
	}

}


// **********************************************************
// HIDE A11Y IN EDIT MODE
body.section-edit {
	#sidebarActions, button.gdpr-privacy-show-banner {
		display: none;
	}
}


// **********************************************************
// SIDEBAR PROPERTIES
#sidebar-properties {  
	.ui.segment {
    font-size: $font-size-xs;
  }
	input[type="text"] {
		width: 100%;
		box-sizing: border-box;
		flex-shrink: 1;
	}
	#blockform-fieldset-default { 
		padding-bottom: 8rem;
	}
	.slate-editor p {
		margin-bottom: .75em !important;
	}
}


// **********************************************************
// COLORS
section.bg-background-02,
section.bg-background-03,
section.bg-background-04 {
	color: var(--#{$prefix}body-color)
}

section.bg-background-01{
	color: var(--#{$prefix}white-text)
}


// **********************************************************
// ERROR
.ui.form .ui.basic.red.label.form-error-label {
 color: var(--#{$prefix}error-color) !important;
}
.inline.field.required .wrapper > label[for]::after, 
.inline.field.required .wrapper .ui.label::after {
	background-color: var(--#{$prefix}error-color);
}


// **********************************************************
// LISTS 
.pageBlocks, .text-slate-editor-inner {
	ol, ul {
		margin-bottom: 2.5rem;
		li {
			margin-bottom: 1.5em;
		}
	}
}
}


// **********************************************************
// HEADINGS
.pageBlocks {
  h2 {
    font-size: $h4-font-size;
  }
  h3 {
    font-size: $h5-font-size;
  }
}


// **********************************************************
// HERO
.block.hero {
	margin-top: 2rem;
	margin-bottom: 2rem;
	.hero-body {
		background-color: var(--#{$prefix}background-03);
		color: var(--#{$prefix}body-color);
	}
}


// **********************************************************
// IMAGE SELECTOR
.image .toolbar-inner {
	.ui.buttons {
		flex-shrink: 0;
	}
	.ui.input {
		font-size: $font-size-xs;
		overflow: hidden;
		flex: 1 1 auto;
	}
}
.grid-image-wrapper img, .gridBlock .block.image img, .block-editor-gridBlock .block-editor-image img, .block-editor-image.contained img {
	aspect-ratio: auto;
}