// **********************************************************
// ANIMATION 
$animationSpeed: calc( var(--#{$prefix}logo-number, 8) * 10s );
$logoWidth: 10rem;
$logoStripWidth: ( $logoWidth + 5rem );

// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(#{$logoStripWidth} * -1 * var(--#{$prefix}logo-number)))}
}

// **********************************************************
// LAYOUT
.clients { 	
	--#{$prefix}client-background-rgb: var(--#{$prefix}background-01-rgb);
	--#{$prefix}client-color: var(--#{$prefix}white-text);

	margin-top: 0; 
	margin-bottom: 8rem;
	padding-top: 3.5em; 
	padding-bottom: 2.5rem;
	&:not(.bg-background-01):not(.bg-background-02):not(.bg-background-03) {
		background-color: var(--#{$prefix}background-01);
		color: var(--#{$prefix}white-text);
		.clients__title.ui.header {
			font-weight: $font-weight-normal;
		}
	}
	&.bg-background-01 { 
		--#{$prefix}client-background-rgb: var(--#{$prefix}background-01-rgb);
		--#{$prefix}client-color: var(--#{$prefix}white-text);
	}
	&.bg-background-02 { 
		--#{$prefix}client-background-rgb: var(--#{$prefix}background-02-rgb);
		--#{$prefix}client-color: var(--#{$prefix}body-color);
		section.bg-background-02 + & {
			margin-top: -8rem;
		}
	}
 	&.bg-background-03 { 
		--#{$prefix}client-background-rgb: var(--#{$prefix}background-03-rgb);
		--#{$prefix}client-color: var(--#{$prefix}body-color);
	}
 	&.bg-background-04 { 
		--#{$prefix}client-background-rgb: var(--#{$prefix}background-04-rgb);
		--#{$prefix}client-color: var(--#{$prefix}body-color);
	}
	.ui.segment {
		width: 100vw;
		padding: 0;
	}
	&__pauseButton {
		&, &.ui.button {
			transition: $transition-base;
			opacity: .33;
			box-shadow: none;
			background-color: transparent;
			width: 2.5rem;
			height: 2.5rem;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			border: 1px solid transparent;
			color: var(--#{$prefix}client-color);
			padding: 0;
			&:hover, &:focus {
				background-color: var(--#{$prefix}client-color);
				color: rgb(var(--#{$prefix}client-background-rgb));
				opacity: 1;
			}
		}
	}
	&--editMode {
		.ui.segment {
			width: auto;
		}
		.clients__wrapper {
			&::before, &::after {
				content: none;
			}
		}
		.clients__track {
			flex-wrap: wrap;
			animation: none;
			width: 100%;
			justify-content: center;
		}
		
	}
	&__title.ui.header {
		display: block !important;
		color: var(--#{$prefix}client-color);
		text-align: center;
		margin-top: 0;margin-bottom: 3rem;
		font-size: $font-size-sm; 
	}
	&__wrapper {
		margin: auto;
		overflow:hidden;
		position: relative;
		width: 100%;		
		&::before,
		&::after {
			content: "";
			background: linear-gradient(to right,  rgba(var(--#{$prefix}client-background-rgb),1) 0%,rgba(var(--#{$prefix}client-background-rgb),0) 100%);
			position: absolute;
			top: 0;
			bottom: 0;
			width: 40px;
			z-index: 2;
		}
		&::after {
			right: 0;
			transform: rotateZ(180deg);
		}	
		&::before {
			left: 0;
		}
		
	}
	&__track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		--#{$prefix}double-logo-number: calc(var(--#{$prefix}logo-number) * 2);
		width: calc(14rem * var(--#{$prefix}double-logo-number));
		.clients--stop & {
			animation: none;
		}
	}

	// 1280 pixels - 80em
	@include media-breakpoint-up(xl) {
		&__wrapper { 	
			&::before,
			&::after {
				width: 8rem;
			}
		}
	}
}

// **********************************************************
// 	CLIENT ITEM
.clientsItem {
	display: block;
	padding: 0 2.5rem;
	margin: 0;
	.ui.image {
		width: $logoWidth;
		height: 5rem;
		object-fit: contain;
		object-position: center center;
	}
	.clients--stop &--clone {
		display: none;
	}
}
