.slideCards {
  --slide-spacing: 0;
	--slide-size: 100%;
	--slide-height: auto; 
  --#{$prefix}sc-text-color: var(--#{$prefix}body-color);

  margin-bottom: 8rem;
  color:var(--#{$prefix}sc-text-color);
  &[class^='bg-background'], &[class*=' bg-background'] {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  &.bg-background-01 {
    --#{$prefix}sc-text-color: var(--#{$prefix}white-text);
  }
  &__title {
    &.ui.header, & {
      margin-bottom: 3rem;
      text-align: center;
      justify-content: center !important;
      font-size: $h4-font-size;
      color:var(--#{$prefix}sc-text-color);
    }
    &--noDesc {
      &.ui.header, & {
        margin-bottom: 3rem;
      }
    }
  }
  &__desc {
    margin-bottom: 4rem !important;
    text-align: center;
    color:var(--#{$prefix}sc-text-color);
  }
  .slider{
    &__slide {
      padding: 0.5rem;
    }
    &__dots {
      margin-top: 2.5rem;
    }
    &-wrapper {
      height: auto;
    }
  }
  // 576 ixels - 36em
  @include media-breakpoint-up(sm) {
    --slide-size: 50%;
  }
  // 992 pixels - 62em
  @include media-breakpoint-up(lg) {
    --slide-size: 33.33%;
    .slider{
      &__slide {
        padding: 1rem;
      }
    }
  }
}


// **********************************************************
// SLIDE CARDS ITEM
.slideCardsItem {
  &__wrapper {
    padding: 1rem;
  }
  &.ui.card {
    width: 23rem;
    border-radius: .5rem;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
    padding: 0;
    .content {
      padding: 1.5rem 2rem 3rem 2rem;
      text-align: center;
      p {
        text-align: center;
      }
    }
    
  }
  .ui.card .content > &__title.header {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    margin: 0;
  }
  // 576 ixels - 36em
  @include media-breakpoint-up(sm) {
    .slider__slide & {
      height: 100%;
    }    
  }
}