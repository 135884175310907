@media print { 
  
  body {
    --#{$prefix}background-01: transparent;
    --#{$prefix}background-01-rgb: 255, 255, 255;
    --#{$prefix}background-02: transparent;
    --#{$prefix}background-02-rgb: 255, 255, 255;
    --#{$prefix}background-03: transparent;
    --#{$prefix}background-03-rgb: 255, 255, 255;
    --#{$prefix}background-04: transparent;
    --#{$prefix}background-04-rgb: 255, 255, 255;

    font-size: 12pt;
  }

  .ui.basic.segment.content-area {
    padding-left: 1em;
    padding-right: 1em;
  }
  .ui.segment {
    font-size: 12pt;
  }

  .full-width {
    position: static;
    right: auto;
    left: auto;
    width: auto !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  
  .slideControls,
  .tabsItem__text__expand,
  .clients,
  .readMoreItem,
  .button,
  .portalFooter,
  .bigBanner__img,
  button.gdpr-privacy-show-banner,
  .gdpr-privacy-banner,
  .documentFirstHeading::before,
  .block.video,
  .documentActions,
  .bigBanner,
  .greenIcon,
  .searchList,
  .pagination {
    display: none !important;
  }

  .block.gridBlock .styled {
    margin: 0 !important;
    padding: 0 !important;
  }

  .column {
    width: 100% !important;
    display: block !important;
  }

  .ui.card {
    break-inside: avoid;
  }

  h1.ui.header {
    font-size: 24pt;
  }
  h2, h2.ui.header {
    font-size: 20pt;
  }
  h3, h3.ui.header {
    font-size: 15pt;
  }

  // **********************************************************
  // NEWS
  .newsSection {
    .ui.grid > .row {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .relatedNews {
    break-inside: avoid;
    .ui.grid > .row {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .newslisting {
    .innerHeader__wrapper {
      display: none;
    }
    .newsListing.container {
      .ui.grid > .row {
        display: grid !important;
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  // **********************************************************
  // FRONTPAGE
  .claimFP {
    display: flex;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-bottom: 4.5rem;
    
  }
  .iconList {
    .ui.grid > .row {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 4.5rem;
    }
  }
  .cardsListing {
    .ui.grid > .row.cardsListing__cardGroup {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 4.5rem;
    }
  }

  // **********************************************************
  // HEADER 
  .innerHeader {
    &__wrapper {
      .ui.grid > .row {
        display: grid !important;
        grid-template-columns: 3fr 1fr;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .innerHeader2 {
    &__img {
      max-width: 100%;
      height: auto;
    }
  }

  .documentDescription {
    font-size: 16pt;
  }


  // **********************************************************
  // BLOG LIST
  .blogView__list {
    .ui.fluid.card {
      flex-direction: row-reverse;
      align-items: flex-start;
    }
  }
  .blogItem {
    break-inside: avoid;
    
    &__imgWrapper {
      width: 25%;
    }
    &__img {
      aspect-ratio: 1/1;
      object-fit: contain;
      height: auto;
      width: 100%;
    }
  }


  // **********************************************************
  // CARDS SLIDER
  .cards-slider {
    .slider__container {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr);
      transform: none !important;
      gap: 1rem;
    }
  }


  // **********************************************************
  // GRID BLOCK 2
  .gridBlock2ImgText {
    .ui.grid {
      break-inside: avoid;
      display: grid !important;
      grid-template-columns: 2fr 3fr;
    }
    img {
      width: 100%;
      height: auto;
    }
  }


  // **********************************************************
  // TESTIMONIALS
  .testimonials {
    .ui.card {
      width: 50% !important;
      margin: 3rem auto;
      padding: 1em;     
    }
  }


  // **********************************************************
  // PROJECTS
  .projectItem {
    margin-bottom: 2rem;
    .ui.grid {
      break-inside: avoid;
      display: grid !important;
      grid-template-columns: 2fr 1fr;
      img {
        width: 100%;
        height: auto;
        aspect-ratio: 9 / 16;
        object-fit: cover;
      } 
    }
  }

  .pointsListing {
    margin-bottom: 4rem;
    .ui.grid > .row {
      display: grid !important;
      grid-template-columns: 1fr 2fr;
    }
  }
}