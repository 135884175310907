// Code to fix Semantic UI behaviours
// **********************************************************
// TOC
// FOCUS
// BUTTONS
// CONTAINER
// DROPDOWN
// FORMS 
// TOOLBAR
// CONTROL PANEL
// FOLDER CONTENTS
// SEGMENT
// HEADERS
// EMBED
// CARDS
// **********************************************************

// **********************************************************
// FOCUS
@mixin outline-double-line {
	/* inner indicator */
	outline: 2px solid var(--#{$prefix}primary)  !important;
	outline-offset: 0;
	/* outer indicator */
	box-shadow: 0 0 0 6px var(--#{$prefix}white) !important;
}
*:focus-visible {	
	@include outline-double-line();
}


// **********************************************************
// BUTTONS
.ui.button {
	transition: text-decoration $transition-fast;
	font-size: $btn-font-size;
	font-weight: $font-weight-normal;
	padding: $btn-padding-y $btn-padding-x;
	border-radius: $btn-border-radius;
	position: relative;
	text-decoration: underline;
	text-underline-offset: .5em;
	text-decoration-color: transparent;

	&:focus-visible {
		@include outline-double-line();
	}
	&:hover, &:focus {
		text-decoration-color: currentColor;
		
	}
	&.inverted {
		color: var(--#{$prefix}white-text);
		&:hover, &:focus {
			background-color: transparent;
			color: var(--#{$prefix}white-text);
		}
	}
	&.primary {
		background-color: var(--#{$prefix}primary);
		&:focus, &:hover {
			background-color: var(--#{$prefix}primary);
		}
	}
	&.black {
		background-color: var(--#{$prefix}body-color);
		color: var(--#{$prefix}white-text);
		&:hover, &:focus {
			background-color: var(--#{$prefix}body-color);
			color: var(--#{$prefix}white-text);			
		}
	}
} 


// **********************************************************
// CONTAINER
body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .ui.container:not(.fluid) {
	// 1600 pixels - 100em
	@include media-breakpoint-up(xxl) {
		width: map-get($map: $container-max-widths, $key: 'xxl') !important;
	}
	
}

body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .ui.container:not(.fluid) {
	@include media-breakpoint-up(xxl) {
		width: calc(map-get($map: $container-max-widths, $key: 'xxl') - 80px)  !important;
	}
	
}
body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .ui.container.fluid,
body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .ui.container.fluid {
	// 768 pixels - 48em
	@include media-breakpoint-up(md) {
		width: calc( 100% - 2em)  !important;
		
	}
}
body.hasRelatedContent .content-area.ui.segment {
	margin-bottom: 0;
}

// **********************************************************
// DROPDOWN
.ui.dropdown > .dropdown.icon {
	font-size: 1em;
}
.ui.menu .dropdown.item .menu {
	background-color: var(--#{$prefix}bgMenu);
}
.ui.dropdown.selected, .ui.dropdown .menu .selected.item {
	color: var(--#{$prefix}secondary);
}


// **********************************************************
// FORMS 
.ui.form input:not([type]), .ui.form input[type="date"], 
.ui.form input[type="datetime-local"], 
.ui.form input[type="email"], 
.ui.form input[type="number"], 
.ui.form input[type="password"], 
.ui.form input[type="search"], 
.ui.form input[type="tel"], 
.ui.form input[type="time"], 
.ui.form input[type="text"], 
.ui.form input[type="file"], 
.ui.form input[type="url"] {
	color: var(--#{$prefix}body-color);
	background-color: var(--#{$prefix}white);
	&:focus {
		background-color: var(--#{$prefix}white);
		color: var(--#{$prefix}body-color);
	}
}
.ui.form .field > label,
.ui.checkbox  label {
	color: var(--#{$prefix}body-color);
}


// **********************************************************
// TOOLBAR
.toolbar-content, .controlpanel {
	a {
		text-decoration: none;
	}
}
#toolbar .pastanaga-menu-list li a, #toolbar .pastanaga-menu-list li button {	
	font-family: $font-family-body;
  }

  
// **********************************************************
// CONTROL PANEL
.controlpanel {
	a {
		text-decoration: none;
	}
	h3.ui.header {
		font-size: 1.125rem;
	}
}

  
// **********************************************************
// FOLDER CONTENTS
.folder-contents a {
	text-decoration: none;
}   
  
// **********************************************************
// SEGMENT
.ui.segments .segment, .ui.segment {
	font-size: $font-size-base;
	background-color: var(--#{$prefix}white);
}
   
  
// **********************************************************
// HEADERS
.ui.header {
	color: var(--#{$prefix}body-color);
}


// **********************************************************
// EMBED
.ui.embed {
	& > .placeholder {
		background-image: radial-gradient(transparent 45%, rgba(0, 0, 0, 0.3));
		object-fit: cover;
	}

}


// **********************************************************
// CARDS
.ui.cards > .card .meta, .ui.card .meta,
.ui.cards > .card > .content > .description, 
.ui.card > .content > .description {
	color: var(--#{$prefix}body-color-rgb, .66)
}


// **********************************************************
//LOGIN I PAGE CONTENTS
#page-login, #page-contents, #page-add {
	margin-bottom: 8rem;
}