// **********************************************************
// LAYOUT & COLOR
.testimonials {
  --slide-spacing: 0;
	--slide-size: 100%;
	--slide-height: auto; 
  --#{$prefix}test-text-color: var(--#{$prefix}body-color);
  
  margin-bottom: 8rem;
  &.bg-background-01 { 
    --#{$prefix}test-text-color: var(--#{$prefix}white-text);
  }
  &[class^='bg-background'], &[class*=' bg-background'] {
    padding-top: 6rem;
    padding-bottom: 6.5rem;
    .clients + &{
      margin-top: -8rem;
    }
  }
  .slider-wrapper {
    margin-bottom: 4rem; 
    height: auto !important;
  }
  .slider__viewport { 
    margin-bottom: 3.5rem;
  }
  .slider__dot {
    background-color: var(--#{$prefix}background-04);
    &--selected {
      background-color: var(--#{$prefix}secondary);
    }
  }
  .ui.button {
    width: 13rem; // 208px
  }
  &--1item {
    .slider__slide {
      margin-left: auto;
      margin-right: auto;
    }
    .slideControls {
      display: none;
    }
  }
  &__title.ui.header, &__intro {
    color: var(--#{$prefix}test-text-color);
  }

  // 992 pixels - 62em
  @include media-breakpoint-up(lg) {
    --slide-spacing: 2rem;
    --slide-size: 50%;
    --slide-height: auto; 
    &--2item {
      .slideControls {
        display: none;
      }
    }    
  }
}


// **********************************************************
// TESTIMONIAL ITEM 
.testiItem {
  &__wrapper {
    padding: 1rem;
  }
  &.ui.card {
    display: grid;
    grid-template-columns: 5rem 1fr;
    grid-template-rows: auto 1fr;
    align-items: center;
    gap: 1rem;
    width: 100%;
    flex-grow: 1;
    border-radius: var(--#{$prefix}border-radius-lg);
    background-color: var(--#{$prefix}white);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17); 
    padding: 1rem;
    &.testiItem--hasLogo {
      grid-template-rows: 5rem 1fr auto;
    }
  }
  &__img {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    &.ui.image {
      width: 5rem;
      height: 5rem;
      object-fit: cover;
      margin: 0;
      border-radius: 50% !important;
    }
  }
  &.ui.card > .content {
    border-top: 0;
    padding: 0;
  }
  .ui.basic.segment &__title {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    &.header {
      font-size: $font-size-xs;
      font-weight: $font-weight-normal;
      margin: 0;
      display: block;
    }
    p {
      margin-bottom: 0;
      display: inline;
      white-space: nowrap;
    }
  }
  &__text {
    grid-column: 1/3;
    grid-row: 2/3;
    display: flex;
    .icon {
      display: none;
    }
    &::before, &::after {
      content:'';
      flex-shrink: 0;
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160zm352-160l-160 160c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L301.3 256 438.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      width: 1em;
      height: 1em;
      background-size: contain;
      opacity: .33;
    }
    &::before {
      margin-right: .25em;
    }
    &::after {
      margin-left: 0.25em;
      align-self: flex-end;
      transform: rotate(180deg);
    }
    .quote {
      flex: 1 1 0;
      width: auto;
      margin-bottom: 0;
    }
  }
  &__logo {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    text-align: right;
    img {
      height: 2.5rem;
      width: auto;
    }
  }

  // 576 ixels - 36em
  @include media-breakpoint-up(sm) {
    &.ui.card {
      padding: 2.5rem 2rem 4.5rem 2.5rem;
      row-gap: 0;
    }
    &__wrapper {
      gap: 0 1rem;
    }
    &__img {
      &.ui.image {
        margin-right: 1rem;
        margin-left: 0;
      }
    }
    &__text,
    &__logo {
      grid-column: 2 / 3;
    }
  }

  // 992 pixels - 62em
  @include media-breakpoint-up(lg) {
    &__wrapper {
      gap: 0 1.5rem;
    }
  }
}

// **********************************************************
// EEA blockquote
blockquote.eea {
  padding: 1.5rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
  border-radius: var(--#{$prefix}border-radius-lg);
  background-color: var(--#{$prefix}white); 
}