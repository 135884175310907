// **********************************************************
// HEADER
.portalHeader {
	&.ui.segment {
		padding-left: 0;
		padding-right: 0;
		background-color: var(--#{$prefix}bgHeader);
	}
	&__container {
		&.ui.container{
			display: flex;
			align-items: center;
			// 992 pixels - 62em
			@include media-breakpoint-up(lg) {
				position: relative;
			}
		}
		.ui.segment {
			padding-left: 0;
			padding-right: 0;
		}
	}
	&.affix.ui.segment, &.affix {
		position: fixed !important;
		z-index: 99;
		top:0; 
		left: 0;
		width: 100%;
		box-shadow: $box-shadow;
		padding-top: 0;
		padding-bottom: 0;
		& + .affixFixer {
			display: block;
		}
		.portalLogo {
			transform: scale(.66);
		}
	}

	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		&__container {
			&.ui.container {
				display: grid; 
				grid-template-columns: auto 1fr auto;
				grid-template-rows: auto auto;
				gap: 1rem;
				.portalLogo__link {
					grid-column: 1 / 2;
					grid-row: 1 / 2;
				}
				.mnPrincipal { 
					grid-column: 1 / 4;
					grid-row: 2 / 3;
					.menu.pointing {
						justify-content: center;
						margin-right: 2.5rem;
					}
				}
				.langSelector {
					grid-column: 3 / 4;
					grid-row: 1 / 2;
				}				
			}
		}
	}

	// 1280 pixels - 80em
	@include media-breakpoint-up(xl) {
		&__container {
			&.ui.container {
				grid-template-columns: auto 1fr auto;
				grid-template-rows: auto minmax(0, auto);
				row-gap: 0;
				.mnPrincipal {
					grid-column: 2/3;
					grid-row: 1/2;
				}
				// Control expanded font
				body.bigFont & {
					.mnPrincipal { 
						grid-column: 1 / -1;
						grid-row: 2 / 3;						
					}
				}
			}
		}
	}
}
.affixFixer {
	display: none;
	height: 111px;
	flex: 0 0 auto;
}

// **********************************************************
// LOGO
.portalLogo {
	transition: $transition-base;
	transform-origin: left center;
	width: auto;
	height: 2.5rem;
	&__link {
		flex-shrink: 0;
	}
	
	// 1280 pixels - 80em
	@include media-breakpoint-up(xl) {
		height: 3.5rem;
	}
}

// **********************************************************
// LANGUAGES
.langSelector {
	text-transform: uppercase;
	display: flex;
	justify-content: flex-end;
	a{
		color: var(--#{$prefix}$body-color);
		text-decoration: none;
		width: 2.5rem;
		height: 2.5rem;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		&.selected {			
			text-decoration: none;
			color: var(--#{$prefix}body-color);
			border-radius: var(--#{$prefix}border-radius-sm);
			text-decoration: underline;
			text-decoration-thickness: 2px;
			text-decoration-color: var(--#{$prefix}secondary);
		}
		&:hover, &:focus {
			color: var(--#{$prefix}link-hover-color) !important;
		} 
	}
}

// **********************************************************
// DIRECT ACTIONS
.directActions {
	&.ui.segment {
		position: fixed !important;
		right: 0;
		top: 33%;
		display: flex;
		flex-direction: column;
		z-index: $zindex-fixed;
		padding: 0;
		margin: 0;
	}
	&__btn.ui.button {
		border-radius: 0;
		background-color: var(--#{$prefix}directActions-bg);
		color: var(--#{$prefix}directActions-color);		
		box-shadow: 0px 0px .3rem rgba($black, 0.16);		
		height: 3rem;
		width: 3rem;
		padding: 0;
		margin: 0;
		font-size: 1.25rem;
		&:hover, &:focus {
			--#{$prefix}directActions-bg: var(--#{$prefix}secondary);
    	--#{$prefix}directActions-color: var(--#{$prefix}body-color);
			text-decoration: none;
		}
		& + .ui.button {
			border-top: 1px solid var(--#{$prefix}body-color);
		}
		&:first-child {
			border-radius: var(--#{$prefix}border-radius-lg) 0 0 0;
		}
		&:last-child{
			border-radius: 0 0 0 var(--#{$prefix}border-radius-lg);
		}
		&.selected {
			--#{$prefix}directActions-color: var(--#{$prefix}background-01);
			--#{$prefix}directActions-bg: var(--#{$prefix}background-02);
		}
	}
}
body:has(button.gdpr-privacy-show-banner) .directActions__btn.ui.button:last-child{
	border-radius: 0;
}

// **********************************************************
// HEADER INTERIOR 1
.innerHeader {
	&__wrapper {
		--#{$prefix}header-text-color1: var(--#{$prefix}white-text);
		--#{$prefix}header-text-color2: var(--#{$prefix}secondary);
		&, &.ui.segment.basic {
			background-color: var(--#{$prefix}background-01);
			background-image: linear-gradient(to top, var(--#{$prefix}white) 0rem, var(--#{$prefix}white) 4rem, var(--#{$prefix}background-01) calc(4rem + 1px), var(--#{$prefix}background-01) 100%);
			color: var(--#{$prefix}header-text-color1);
			padding: 3.5rem 0 0 0;
			margin: 0 0 8rem 0;
		}
		&--noImage, &--noImage.ui.basic.segment {
			background-image: none;
			& + .content-area.ui.segment {
				margin-top: 0;padding-top: 0;
			}
		}
	}
	&__title.ui.header {		
		color: var(--#{$prefix}header-text-color1);
		font-size: 3.75rem;
		font-size: clamp($h3-font-size, 4vw , 3.75rem);
		margin: 0 0 1em 0;
	}
	&__title__prefix {
		color: var(--#{$prefix}tertiary);
		padding-top: 1.5rem;
		font-size: $font-size-base;
		font-size: clamp($font-size-xs, 1.25vw, $font-size-base);
		margin-bottom: 1rem;
		font-weight: $font-weight-bold;
	}
	&__description.documentDescription {
		font-size: clamp($h6-font-size, 2.5vw , 1.5rem);
		margin-bottom: 3em;
		color: var(--#{$prefix}header-text-color1);
	}
	&__imgWrapper {
		--#{$prefix}greenIcon-fs: 2.5rem;
		--#{$prefix}greenIcon-radius: .25rem;
		--#{$prefix}greenIcon-height: 5rem;
		position: relative;
		margin-top: 3rem;
		border-radius: var(--#{$prefix}border-radius-lg);
		overflow: hidden; 
		&::after {
			content:'';
			display: block;
			position: absolute;
			inset: 0;
			background: linear-gradient(to top right, rgba(var(--#{$prefix}secondary-rgb), .3) 0%, rgba(var(--#{$prefix}secondary-rgb), 0) 100%);
			opacity: 0.66;
			z-index: $zindex-flat;
		}
		.greenIcon {			
			margin:0;
			position: absolute;
			inset: 1rem auto auto 1rem;
			z-index: $zindex-flat + 2;
		}
		// 768 pixels - 48em
		@include media-breakpoint-up(md) {
			margin-top: 0;
		}
	}
	&__img {
		display: block;
		width: 100%;
		height: auto; 
	}

	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		&__wrapper {
			padding-top: .5rem;
			background-image: none;
			margin-bottom: 3rem + 8rem;
			&--noImage {
				margin-bottom: 0;
			}
		}
		&__text {
			padding-top: 3rem;
			padding-bottom: 2rem;
		}
		&__imgWrapper {
			transform: translateY(3rem);
			height: 100%;
			.ui.image {
				height: 100%;
			}
		}
		&__img {
			height: 100%;
			object-fit: cover;
		}
	}
}

// **********************************************************
// HEADER INTERIOR 2
.innerHeader2__wrapper {
	--#{$prefix}header-text-color1: var(--#{$prefix}white-text);
	--#{$prefix}header-text-color2: var(--#{$prefix}secondary);
	margin-bottom: 3rem;
	padding-top: 5rem;
	text-align: center;
	background-color: var(--#{$prefix}background-01);

	body.template-newsitem & {
		margin-bottom: 0;
	}
	.innerHeader{
		&__title__prefix {
			text-align: center;
			margin-bottom: 1rem;
			margin-left: auto;margin-right: auto;
		}
	}
	.innerHeader__description.documentDescription {
		margin-bottom: 1.5rem;
	}
	.innerHeader2__imgWrapper {
		background-image: linear-gradient(to bottom, var(--#{$prefix}background-01) 0rem, var(--#{$prefix}background-01) 50%, var(--#{$prefix}white) calc(50% + 1px), var(--#{$prefix}white) 100%);
		margin:0;
		padding-bottom: 1.5rem;
	}
	.innerHeader2__img {
		aspect-ratio: 16 / 9;
		border-radius: var(--#{$prefix}border-radius-2xl);
		object-fit: cover;
		border: none;
		width: 100%;
		margin-top: 3rem;
		height: auto;		
		display: block;
		margin-left: auto;
		margin-right: auto;
		&__caption {
			margin-top: 0.5rem;
			font-size: $font-size-xs;
			color: var(--#{$prefix}primary);
			text-align: left;
		}
	}
	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		.innerHeader{
			&__title.ui.header, &__description{
				max-width: 80%;
				margin-left: auto;
				margin-right: auto;
			}
		}
		.innerHeader2__img {
			max-width: 80%;
			&__caption {
				max-width: 80%;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}