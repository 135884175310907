// **********************************************************
// PROJECTS LIST
.projectsList {
  margin-bottom: 8rem;
}

// **********************************************************
// PROJECTS ITEM
.projectItem {
  &, &.ui.segment {
    padding-top: 8rem;
    padding-bottom: 5.5rem;
    &:nth-child(even) {
      background-color: var(--#{$prefix}background-02);
      // 992 pixels - 62em
      @include media-breakpoint-up(lg) {
        & > .grid {
          flex-direction: row-reverse;
        }
      }
    }
    & > .grid {
      justify-content: space-between;
    }
  }
  &__img {
    width: 100%;
    height: auto;
    aspect-ratio: 9 / 16;
    object-position: top center;
    object-fit: cover;
    border-radius: var(--#{$prefix}border-radius-lg);
    // 992 pixels - 62em
    @include media-breakpoint-up(lg) {
      max-height: calc(100% - 2rem);
      position: absolute;
      top: 1rem;
      left: 1rem;
      width: calc(100% - 2rem);
      aspect-ratio: auto;
    }
  }
  &__title {
    &.ui.header {
      font-size: $h4-font-size;
      margin: 0 0 1.25rem 0;
    }
  }
  &__desc {
    font-size: $h5-font-size;
    margin-bottom: 1.5rem;
  }
  &__link {
    text-decoration: none;
    color: var(--#{$prefix}body-color);
  }
  &__subject {
    --#{$prefix}greenIcon-height: 2.5rem;
    --#{$prefix}greenIcon-fs: #{$font-size-base};

    padding-top: 3rem;

    &__title {
      &.ui.header {
        margin-bottom: 2rem;
        font-size: $h5-font-size;
      }
    }
    &__list {
      margin: 0;      
    }
    &__item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .greenIcon {
      margin-right: 1rem;
    }
  }
}