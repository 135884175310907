// **********************************************************
// LAYOUT
#claim-front-page {
	padding-bottom: 8rem;
}
.claimFP {	
	--#{$prefix}claim-text-color: var(--#{$prefix}white-text);
	position: relative;
		
	// 768 pixels - 48em
	@include media-breakpoint-up(md) {
		border-radius: var(--#{$prefix}border-radius-lg, .5rem);
		overflow: hidden;
		position: relative;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: 1fr auto;		
	}
}


// **********************************************************
// CLAIM ITEM

@keyframes guaxi_animation {
  0% {
    transform: translateY(.66em);
		opacity: .33;
  }
  100% {
		transform: translateY(0);
		opacity: 1;
  }
}

.claimFPItem {
	position: relative;
	margin-bottom: 1rem;
	&__img.ui.image {
		animation: guaxi_animation .66s ease-in-out forwards;
		transform-origin: top center;
		transform: none !important;
		position: absolute;
		inset: 0;
		z-index: $zindex-flat;
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;			
		}
	}
	&__text {		
		background-image: linear-gradient(to bottom, rgba($background03, .66) 0%, rgba($primary, 0.03) 2%, rgba($primary, 0.66) 50%, rgba($primary, 0.66) 100%);
		color: var(--#{$prefix}claim-text-color);
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		z-index: $zindex-flat + 1;
		padding: 1rem;
		position: relative;	
		width: 100%;	
		height: 56.25vw;
		@supports (aspect-ratio: 16 / 9) {
			height: auto;
			aspect-ratio: 16 / 9;
		}
	}
	&__title.ui.header {		
		animation: guaxi_animation .66s ease-out forwards;
		transform-origin: center left;
		color: var(--#{$prefix}claim-text-color);
		font-size: $h3-font-size;
		font-size: clamp($h5-font-size, 4vw , 3.75rem);
		line-height: 1.1;
	}
	&__desc {
		animation: guaxi_animation .66s ease-out forwards;
		transform-origin: center left;
		padding-top: 1rem;
		border-top: 1px solid currentColor;
		font-size: $h4-font-size;
		font-size: clamp($font-size-xs, 2.5vw, 1.75rem);
		line-height: 1.1;
	}

	// 768 pixels - 48em
	@include media-breakpoint-up(md) {
		margin-bottom: 0;
		grid-column: 1 / 5;
		grid-row: 1 / 3;
		z-index: $zindex-flat;
		position: relative;
		display: none;
		&.selected {
			display: block;
		}
		&__text {
			background: linear-gradient(to bottom left, rgba(47, 98, 79, 0) 0%, rgba(44, 94, 76, 0.03) 2%, var(--#{$prefix}primary) 100%);
			justify-content: center;
			height: calc( #{map-get($grid-breakpoints, 'md')} * 0.5625 );
			@supports (aspect-ratio: 16 / 9) {
				height: auto;
			}
		} 
		&__title.ui.header {
			margin-bottom: .5em;
		}
		&__desc {
			padding-top: 0;
			border-top: none;
		}
	}

	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		&__text {			
			height: calc( #{map-get($grid-breakpoints, 'lg')} * 0.5625 );
			@supports (aspect-ratio: 16 / 9) {
				height: auto;
			}
		} 
	}

	// 1280 pixels - 80em
	@include media-breakpoint-up(xl) {
		&__text {
			padding: 2rem;
			height: calc( #{map-get($grid-breakpoints, 'xl')} * 0.5625 );
			@supports (aspect-ratio: 16 / 9) {
				height: auto;
			}
		}
		&__title.ui.header, &__desc {
			text-wrap: balance;
		}
	}
	
}


// **********************************************************
// BUTTONS
.claimFP__button.ui.button {
	transition: filter $transition-fast;
	display: none;
	padding: 1rem;
	font-weight: $font-weight-bold;
	color: var(--#{$prefix}claim-text-color) !important;
	box-shadow: none;
	font-size: $h5-font-size;
	font-size: clamp($font-size-xs, 1.5vw, $h5-font-size);
	background-color: transparent;
	&:hover, &:focus, &.selected {
		text-decoration: none !important;
		box-shadow: none !important;
		color: var(--#{$prefix}claim-text-color) !important;
		filter: blur(3px);
		background-color: transparent !important;
	}

	// 768 pixels - 48em
	@include media-breakpoint-up(md) {	
		display: inline-flex;
		position: relative;
		z-index: $zindex-flat + 1;
		grid-row: 2 / 3;
		@for $i from 1 through 4 {
			&:nth-of-type(#{$i}) {
				grid-column: #{$i} / #{$i + 1};
			}
		}
	}

	// 1280 pixels - 80em
	@include media-breakpoint-up(xl) {
		padding: 2rem;
	}
}