// **********************************************************
// LAYOUT 
.blogView {
  &__list {
    padding-top: 8rem;
  }
}

// **********************************************************
// BLOG ITEM - BLOG 1
.blogItem {
  --#{$prefix}greenIcon-height: 5rem;
  --#{$prefix}greenIcon-fs: 2.5rem;

  &, &.ui.card {
    border-radius: var(--#{$prefix}border-radius-lg) !important;
    margin-bottom: 2.5rem;
    & > *:first-child {
      border-radius: var(--#{$prefix}border-radius-lg) !important;
    }
  }
  &__title {
    &, .ui.card > .content &.header {
      font-size: $h4-font-size;
      margin-top: 0;
    }
  }
  &__text {
    &, &.content {
      border-top: 0 !important;
    }
  }
  &__link {
    color: var(--#{$prefix}body-color);
    text-decoration: none;
    &:focus, &:hover {
      color: var(--#{$prefix}body-color);
      text-decoration: none;
    }
  }
  &__imgWrapper {
    margin: 0;
    position: relative;
    min-height: 8rem;
    .greenIcon {
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
    }
  }
  &__img {
    display: block;
    border-radius: var(--#{$prefix}border-radius-lg);
    width: 100%;
    height: auto;
    aspect-ratio: 4/3;
    object-fit: cover;
  }
  &:hover, &:focus-within {
    .readMoreItem {
      text-decoration: underline;
      text-decoration-color: currentColor;
    }
  }

  // 992 pixels - 62em
  @include media-breakpoint-up(lg) {
    &, &.ui.card {
      flex-direction: row-reverse; 
    }
    &__text {
      &, .ui.card > &.content {
        padding: 2.5rem 3rem 2rem 3rem;
        width: 80%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      }
    }
    .extra.content {
      margin-top: auto;
    }
    &__imgWrapper {
      width: 40%;
      flex: 0 0 auto;
      min-height: 21rem; //336px
    }
    &__img {
      width: 100%;
      height: 100%;
    }
  }
}

// **********************************************************
// BLOG ITEM - BLOG 2
.blog2Item {
  &__link {
    color: var(--#{$prefix}body-color);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      text-decoration-color: currentColor;
    }
  }
}