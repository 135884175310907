.pageBlocks .block.form {
  .field {
    .ui.grid .row {
      flex-direction: column;
      margin-left: -1rem;
      margin-right: -1rem;
      & > .column {
        width: 100% !important;
      }
    }
    .wrapper {
      min-height: 0;
      & > label {
        font-size: $font-size-sm;
        font-weight: $font-weight-semibold;
        margin-bottom: 1rem !important;
      }
    }
    &.inline .wrapper {
      border-bottom: none;
    }
    .ui.input  input,
    &.textarea textarea,
    .react-select__control {
      height: 3.5rem;
      background-color: rgba( var(--#{$prefix}background-02-rgb) , .50);
      border: 1px solid var(--#{$prefix}tertiary);
      border-radius: var(--#{$prefix}border-radius-sm);
      padding: 0 1rem;
    }
    &.textarea textarea {
      min-height: 8em;
      margin-top: 0;
    }
    p.help {
      border-bottom: none;  
      font-style: italic;
      color: var(--#{$prefix}gray-700);
    }
    .ui.checkbox input:focus + label::before {
      border-width: 3px;
    }
  }
  .ui.button[type="clear"] {
    background-color: transparent;
    border: 1px solid var(--#{$prefix}body-color);
    color: var(--#{$prefix}body-color);
  }
  .dropzone-placeholder {
    margin-top: 0;
  }
}