
 
  // **********************************************************
  // BORDER 
  @include media-breakpoint-up(sm) {
	.border-top-sm-0        { border-top: none !important ;}
	.border-bottom-sm-0     { border-bottom: none !important ;}
	.border-start-sm-0      { border-left: none !important ;}
	.border-end-sm-0        { border-right: none !important ;}
  }
  @include media-breakpoint-up(md) {
	.border-top-md-0        { border-top: none !important ;}
	.border-bottom-md-0     { border-bottom: none !important ;}
	.border-start-md-0      { border-left: none !important ;}
	.border-end-md-0        { border-right: none !important ;}
  }
  @include media-breakpoint-up(lg) {
	.border-top-lg-0        { border-top: none !important ;}
	.border-bottom-lg-0     { border-bottom: none !important ;}
	.border-start-lg-0      { border-left: none !important ;}
	.border-end-lg-0        { border-right: none !important ;}
  }
  @include media-breakpoint-up(xl) {
	.border-top-xl-0        { border-top: none !important ;}
	.border-bottom-xl-0     { border-bottom: none !important ;}
	.border-start-xl-0      { border-left: none !important ;}
	.border-end-xl-0        { border-right: none !important ;}
  }
  @include media-breakpoint-up(xxl) {
	.border-top-xxl-0        { border-top: none !important ;}
	.border-bottom-xxl-0     { border-bottom: none !important ;}
	.border-start-xxl-0      { border-left: none !important ;}
	.border-end-xxl-0        { border-right: none !important ;}
  }