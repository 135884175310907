
// **********************************************************
// EMBLA
.slider {
	&__viewport {
	  overflow: hidden;
	}
	&__container {
	  backface-visibility: hidden;
	  display: flex;
	  touch-action: pan-y;
	  margin-left: calc(var(--slide-spacing) * -1);
	}
	&__slide {
	  flex: 0 0 var(--slide-size);
	  min-width: 0;
	  padding-left: var(--slide-spacing);
	  position: relative;
	}
	&__slide__img {
	  display: block;
	  height: var(--slide-height);
	  width: 100%;
	  object-fit: cover;
	}
	&__slide__number {
	  width: 4.6rem;
	  height: 4.6rem;
	  z-index: 1;
	  position: absolute;
	  top: 0.6rem;
	  right: 0.6rem;
	  border-radius: 50%;
	  background-color: rgba(var(--background-site-rgb-value), 0.85);
	  line-height: 4.6rem;
	  font-weight: 900;
	  text-align: center;
	  pointer-events: none;
	}
	&__slide__number > span {
	  color: var(--brand-primary);
	  background-image: linear-gradient(
		45deg,
		var(--brand-primary),
		var(--brand-secondary)
	  );
	  background-clip: text;
	  -webkit-background-clip: text;
	  -webkit-text-fill-color: transparent;
	  font-size: 1.6rem;
	  display: block;
	  position: absolute;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	}
	&__dot {
		cursor: pointer;
	}
}

	// **********************************************************
	// SLIDER DOTS
	.slider {
		&__dots {
			display: flex;
			justify-content: center;
			padding: .25rem 0;
		}
		&__dot {
			transition: $transition-base;
			border: none;			
			height: .5rem;
			width: 2.5rem;
			background-color: var(--#{$prefix}dot-color, var(--#{$prefix}background-03));
			border-radius:var(--#{$prefix}border-radius-sm);
			&:not(.slider__dot--selected):hover, &:not(.slider__dot--selected):focus {
				background-color: var(--#{$prefix}dot-selected-color, var(--#{$prefix}tertiary));
			}
			&--selected {
				background-color: var(--#{$prefix}dot-selected-color, var(--#{$prefix}secondary) );
				width: 3.5rem;
			}
			& + & {
				margin-left: 1rem;
			}

		}
	}

	section.bg-background-02 .slideControls,
	section.bg-background-03 .slideControls {
		--#{$prefix}dot-color: 					var(--#{$prefix}background-04);
		--#{$prefix}dot-selected-color: var(--#{$prefix}secondary);
	}

	section.bg-background-04 .slideControls {
		--#{$prefix}dot-color: 					var(--#{$prefix}tertiary);
		--#{$prefix}dot-selected-color: var(--#{$prefix}background-01);
	}