// **********************************************************
// LAYOUT
.pointsListing {
	--#{$prefix}pl-text-color: var(--#{$prefix}body-color);

	margin-bottom: 8rem;
	&.bg-background-01, &.bg-background-02, &.bg-background-03 {
		padding-top: 6rem;
		padding-bottom: 4.5rem;
	}
	&.bg-background-01 {
		--#{$prefix}pl-text-color: var(--#{$prefix}white-text);
		ul.ui.list > .eccList__item::before {
			filter: invert(100%);
		}
	}
	.ui.header, .ui.list {
		color: var(--#{$prefix}pl-text-color);
	}
	// 1280 pixels - 80em
	@include media-breakpoint-up(xl) {
		&__img {
			&.fluid.ui.image, & {
				width: 100%;
			}
		}
		
	}
}

// **********************************************************
// LISTS
.eccList {
	&.ui.list {
		margin-left: 2rem !important;
	}
	ul.list.ui > &__item {
		margin-bottom: 2rem;
		&:last-child {
			margin-bottom: 0;
		}
		&::before {
			content: '';
			width: 20px;
			height: 20px;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M10,20A10,10,0,1,1,20,10,10.011,10.011,0,0,1,10,20ZM6.5,8.894A1,1,0,0,0,5.71,10.51L8.15,13.62a1,1,0,0,0,1.579-.01l4.57-6V7.58A1.006,1.006,0,0,0,12.7,6.36l-3.78,5L7.29,9.28A1.008,1.008,0,0,0,6.5,8.894Z' fill='%23162626'/%3E%3C/svg%3E ");
			background-repeat: no-repeat;
			margin-left: -1.75em;
		}
	}
	&__header {
		display: block;
		font-size: $h5-font-size;
		line-height: $headings-line-height;
		margin-bottom: .5rem;
	}
}