.eccBreadcrumbs {
	&.ui.breadcrumb {
		margin-bottom: 1rem;
		font-size: $font-size-xs;
		.divider {
			color: var(--#{$prefix}body-color);
		}
		a {
			color: var(--#{$prefix}body-color);
		}
	}
}