// **********************************************************
// LAYOUT
.timeLineList {
  --#{$prefix}timeLine-text-color: var(--#{$prefix}body-color); 
  --#{$prefix}timeLine-border-color: var(--#{$prefix}gray-600);

  &.bg-background-01 {
    --#{$prefix}timeLine-text-color: var(--#{$prefix}white-text); 
    --#{$prefix}timeLine-border-color: var(--#{$prefix}tertiary);
  }
  padding-top: 4.5rem;
  padding-bottom: 6.5rem;
  margin-bottom: 8rem;

  &__title {
    margin-bottom: 2rem;
    .segment.ui &.header.ui {
      color: var(--#{$prefix}timeLine-text-color);
      margin-top: 0;
      font-size: $h4-font-size;
      justify-content: center;
      text-align: center;
    }
    &--noDesc {
      color: var(--#{$prefix}timeLine-text-color);
      margin-bottom: 5rem;
    }
  }
  &__desc {
    text-align: center;
    max-width: 38rem; // 608px
    margin: 0 auto 5rem auto;
  }
  &__list {
    padding-left: 1rem;
  }

  // 992 pixels - 62em
  @include media-breakpoint-up(lg) {
    &__list {
      display: flex;
      & > * {
        flex: 1 1 0;
      }
    }
  }
}

// **********************************************************
// TIMELINE ITEM
.timeLineItem {
  &.ui.card {
    background-color: transparent;
    box-shadow: none;
    border: none;
    position: relative !important;
    border-left: 1px solid var(--#{$prefix}timeLine-border-color);
    border-radius: 0;
    margin: 0;
    & > .content {
      padding: .5em 1em 3rem 2rem;
      & > .header {
      color: var(--#{$prefix}timeLine-text-color);
      font-size: $h5-font-size;
      margin-bottom: 1em;
      }
      & > .description {
        color:  var(--#{$prefix}timeLine-border-color);
        font-size: $font-size-base;
        line-height: $line-height-base;
      }
    }
    &::before {
      content: '';
      display: block;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: var(--#{$prefix}secondary);
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-50%);
    }

    // 992 pixels - 62em
    @include media-breakpoint-up(lg) {
      border-left: none;
      border-top: 1px solid var(--#{$prefix}timeLine-border-color);
      & > .content {
        padding: 3.5rem 2rem 1em 0;
      }
      &::before {
        transform: translateY(-50%);
        left: -4px;
      }
    }
  }
}