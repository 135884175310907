// **********************************************************
// FORM
#contact-form {
  margin-bottom: 7rem;
  // Layout
  .Form__grid {
    display: grid;
    gap: 0 1.5rem;
    grid-template-columns: 1fr;
    // 992 pixels - 62em
    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 1fr;     
      .field.textarea, 
      .field:has(.file-widget-dropzone), 
      .field:has(.checkbox-list-widget),
      #privacyText,
      #privacyText + div,
      #privacyText + div + div,
      #privacyPolicyLink  {
        grid-column: 1 / 3;
      }
    }
  }
  .ui.segments, .ui.segment {
    border: none !important;
    box-shadow: none !important;
  }
  // title
  .ui.segment h2.header {
    @include visually-hidden();
  }
  // field
  .field { 
    margin-bottom: 2.5rem;
    &, &.inline {
      .ui.grid > .row {
        flex-direction: column;
        flex-wrap: wrap;
        & > .column {
          width: 100% !important;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    .wrapper {
      border-bottom: none;
      min-height: 0;
      label {
        font-size: $font-size-sm;
        color: var(--#{$prefix}body-color);
        margin-bottom: 1rem;
      }
    }
    &.required .wrapper > label[for]::after {
      width: 0.5rem;
      height: 0.5rem;
      position: relative;
      top: -0.25rem;
    } 
    .checkbox-group {
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 1.5rem;
    }   
    &.field-wrapper-acceptCommercial {
      margin-bottom: 0.5rem;
    }
    .ui.basic.red.label {
      background-color: transparent !important;
    }
  }
  .ui.form {
    .ui.input input:not([type]), 
    .ui.input input[type="date"], 
    .ui.input input[type="datetime-local"], 
    .ui.input input[type="email"], 
    .ui.input input[type="number"], 
    .ui.input input[type="password"], 
    .ui.input input[type="search"], 
    .ui.input input[type="tel"], 
    .ui.input input[type="time"], 
    .ui.input input[type="text"], 
    .ui.input input[type="file"], 
    .ui.input input[type="url"],
    .inline.field.textarea textarea {
      height: 3.5rem;
      border: 1px solid var(--#{$prefix}tertiary);
      border-radius: var(--#{$prefix}border-radius-sm);
      background-color: rgba( var(--#{$prefix}background-02-rgb) , .50 );
      padding-left: 1rem;
      font-size: $font-size-sm;
      &::placeholder {
        color: rgba( var(--#{$prefix}body-color-rgb), .80);
      }
    }
    .inline.field.textarea textarea {
      margin-top: 0;
      padding-top: 1.5rem;
      min-height: 12rem;
    }
  }
  .ui.checkbox{
    label::before {
      background-color: var(--#{$prefix}background-02);
      box-shadow: none;
      border-radius: var(--#{$prefix}border-radius-sm);
      border-color: var(--#{$prefix}tertiary);
    }
    input:checked ~ label::after,
    input:checked .box::after { 
      color: var(--#{$prefix}secondary);
    }
  }
  #privacyText { 
    font-size: $font-size-xs;
    margin-bottom: 2rem;
    & + div + div {
      margin-bottom: 0.5rem;
    }
  }
  button[type="submit"].ui.primary {
    background-color: var(--#{$prefix}primary) !important;
    color: var(--#{$prefix}white-text) !important;
    border-radius: var(--#{$prefix}border-radius-sm);
    margin-left: 0;
    padding: 1.5rem 2.5rem;
    border: none;
    &:hover, &:focus {
      background-color: var(--#{$prefix}primary) !important;
      color: var(--#{$prefix}white-text) !important;
      text-decoration: underline;
    }
    .visually-hidden {
      position: static !important;
      width: auto !important;
      height: auto !important;
      padding: 0 !important;
      margin: 0 !important;
      overflow: hidden !important;
      clip: none !important;
      white-space: nowrap !important;
      border: 0 !important;
    }
    .icon {
      display: none;
    }
    &.right {
      float: none;
      margin-right: auto;
    }
  }
  #privacyPolicyLink {
    font-size: $font-size-xs;
  }

  // Error messages
  .message{
    margin-bottom:1.5rem;
    .header + p {
      display: none;
    }
  }
}
.required.msg {
  display: inline-block;
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  position: relative; 
  background-color: var(--#{$prefix}error-color);
}

// **********************************************************
// MAPA
.contactForm-template {
  .ui.segment.basic.content-area {
    padding-top: 0;
    margin-top: 0;
  }
  .block.maps iframe {
    height: auto;
    aspect-ratio: 16 / 9;
    margin: 0;
  }

}

// **********************************************************
// COORDENADES
.contactCoord {
  margin-bottom: 6rem;
  &__img {
    text-align: center;
    img {
      width: 11rem;
      height: auto;
    }
  }
  &__map {
    border: none;
    width: 100%;
    height: 400px;
    @supports (aspect-ratio: 1 / 1 ) {
      height: auto;
      aspect-ratio: 1 / 1;
    }
  }
  &__address {
    font-style: normal;
  }

}