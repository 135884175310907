// **********************************************************
// SHOW GREEN ICON
@keyframes showGreenIcon {
  0% {
    opacity: .1;
    transform: scale(.25);
    transform-origin: center center;
  }
  80% {
    opacity: 1;
    transform: scale(1.2); 
  }
  100% {
    opacity: 1;
    transform: scale(1); 
  }
}
.showGreenIconAnim {
  animation: showGreenIcon 1s ease-in 0s 1 forwards;
}

// **********************************************************
// ANIMATE HEADER IMAGE
@keyframes showHeaderImage {
  0% {
    opacity: .66; 
    transform: translateY(.5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0); 
  }
}
.showHeaderImageAnim {
  animation: showHeaderImage .5s ease-in 0s 1 forwards;
}