
// **********************************************************
// COLOR
.text-bg-white {
	background-color: rgba( var(--#{$prefix}white-rgb), var(--#{$prefix}bg-opacity, 1));
	color: var(--#{$prefix}primary);
  }
  a.text-bg-white, button.text-bg-white {
	&:hover, &:focus {
		background-color: var(--#{$prefix}secondary);
		color: var(--#{$prefix}primary);
	}
  }
  