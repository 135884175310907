// **********************************************************
// VARIANTS
.gridBlock2ImgText { 
  --#{$prefix}gb-text-color: var(--#{$prefix}body-color);
  
  &.bg-background-01 { 
    --#{$prefix}gb-text-color: var(--#{$prefix}white-text);
  }
  &:not(.block) {
    margin-bottom: 8rem;
  }

// **********************************************************
// LAYOUT
  .gridBlock.block {
    padding-top: 0;
    padding-bottom:0;
    p:last-child {
      margin-bottom: 0;
    }
  }
  &[class^='bg-background'], &[class*=' bg-background'] {
    .gridBlock.block {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }
  .column {
    .styled-image {
      margin: 0 !important;
      padding: 0 !important;
    }
    strong {
      font-size: $h4-font-size;
      display: block;
      line-height: $line-height-base;
    }
    p:has(strong) {
      margin-bottom: 2.5rem;
    }
  }

  // 768 pixels - 48em
  @include media-breakpoint-up(md) {
    .column {
      .styled-image {
        margin-top: auto !important;
        margin-bottom: auto !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          margin-bottom: 0;
        }
        img {
          display: block;
        }
      }
      .styled-slate {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 !important;
        padding: 0 !important;
      }
      
      
    }
  }
}