.iconLinkItem {
    --#{$prefix}iconLinkItemColor: var(--#{$prefix}secondary);
    transition: $transition-base;
    color: var(--#{$prefix}body-color);
    text-align: center;
    
    &.card.ui {
        padding-top: 5rem;
        border-radius: 0;
        border: none;
        box-shadow: none;
        background-color: transparent;
        margin-left: auto;
        margin-right: auto;
        .content {
            display: block;
            border-top: 0;
            padding-top: 0;
        }
    }

    &__icon {
        transition: $transition-base;
        height: 1em;
        margin-bottom: 1rem;
        width: auto;
        font-size: 4rem;
        line-height: 1;
        color: var(--#{$prefix}iconLinkItemColor);
    }

    &__title {
        font-size: 4.5rem;
        font-weight: $font-weight-normal;    
        display: block;
        line-height: 1.1;
        margin-bottom: 1.5rem;
    }

    &__text {
        font-size: $font-size-base;
        line-height: 1.1;
    }

    &[href] {
        text-decoration: none;
        color: var(--#{$prefix}body-color);
        &:hover,
        &:focus {
            --#{$prefix}iconLinkItemColor: var(--#{$prefix}link-hover-color);
            color: var(--#{$prefix}link-hover-color);
            text-decoration: none;
            box-shadow: none !important;
            border: none;
        }
    }
}

// **********************************************************
// ICON CARD 
.iconCard {
    --#{$prefix}iconcard-icon-color: var(--#{$prefix}secondary, #{$secondary});
    transition: $transition-base;
    position: relative;

    &.ui.card {
        border-radius: .5rem;
        display: flex;
        flex-direction: row-reverse;
        border-top: none !important;
        border-color: transparent;
        body.bigFont & {
            flex-wrap: wrap;
        }
    }

    &.ui.card>.content {
        padding: 2rem 1.5rem 1.75rem 1.75rem;
        .header, .description {
            color: var(--#{$prefix}body-color);
        }
    }

    &__icon {
        color: var(--#{$prefix}iconcard-icon-color, #{$secondary});
        font-size: 2rem;
        margin: 2.25rem 2rem 0 0;
        flex-shrink: 0;
    }

    &__title.ui.header {
        font-size: $font-size-base;
        margin-bottom: 0;
    }

    &[href] {
        color:var(--#{$prefix}body-color);
        text-decoration: none;

        &:hover,
        &:focus-within {
            --#{$prefix}iconcard-icon-color: var(--#{$prefix}link-hover-color);
            color: var(--#{$prefix}link-hover-color);
            text-decoration: none;
            & >.content .header, & > .content .description {
                color: var(--#{$prefix}link-hover-color);
            }
        }
    }
}