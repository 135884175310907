.faqList {
  &__title {
    &, .ui.accordion &.title {
      transition: $transition-base;
      margin-bottom: .25rem;
      margin-top: 0;
      background-color: var(--#{$prefix}background-02);
      display: flex;
      align-items: center;
      font-weight: $font-weight-bold;
      padding-left: 1rem;
      padding: .5em 1rem;
      border-radius: var(--#{$prefix}border-radius-sm);
      border-bottom: 1px solid transparent;
      &:hover, &:focus {
        border-bottom-color: var(--#{$prefix}secondary);
      }
      &.active {
        margin-bottom: 0;
        .faqList__icon {
        transform: rotate(180deg);
        }
      }
    }
  }
  &__icon {
    margin-left: auto;
    color: var(--#{$prefix}gray-600);
    transition: transform .25s;
  }
  &__content {
    &, .ui.accordion &.active.content {
      border-radius: var(--#{$prefix}border-radius-sm);
      border: 1px solid var(--#{$prefix}border-color);
      padding: 1rem 1.5rem !important;
      margin-bottom: .5rem;
      font-size: $font-size-sm;
    }
  }
}