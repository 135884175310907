// **********************************************************
// NEWS
.relatedNews {
  &, &.ui.segment {
    margin-top: 8rem;
    margin-bottom: 6rem;
  }
  &__title {
    &.ui.header, & {
      font-size: $h3-font-size;
      margin-bottom: 5rem;
      text-align: center;
      justify-content: center !important;
    }
  }
}