// **********************************************************
// TOGGLE
button.gdpr-privacy-show-banner { 
  bottom: auto;
  top: calc(33% + 6rem);
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--#{$prefix}directActions-bg);
  box-shadow: 0px 0px .3rem rgba($black, 0.16);
  border-radius: 0 0 0 var(--#{$prefix}border-radius-lg);
  padding: 0;
  border:0;
  border-top: 1px solid var(--#{$prefix}body-color);
  opacity: 1;
  z-index: $zindex-fixed;
  img {
    filter: invert(100%);
  }
  &:hover, &:focus {
    --#{$prefix}directActions-bg: var(--#{$prefix}secondary);
    text-decoration: none;
    img {
      filter: none;
    }
  }
}

// **********************************************************
// BANNER
.gdpr-privacy-banner {
  background-color: var(--#{$prefix}light) ;
  .gdpr-privacy-content-wrapper .gdpr-privacy-content .buttons button.gdpr-privacy-banner-button.primary {
  background-color: var(--#{$prefix}body-color);
  color: var(--#{$prefix}white-text);
  &:hover, &:focus {
    background-color: var(--#{$prefix}body-color);
    color: var(--#{$prefix}white-text);			
  }
}}

// **********************************************************
// ALERT
.volto-gdpr-embed-disabled {
  margin-top: 6rem;
  margin-bottom: 6rem;
  font-family: $font-family-sans-serif;
  background-color: var(--#{$prefix}background-01);
  color: var(--#{$prefix}white-text);
  border: none;
  border-radius: var(--#{$prefix}border-radius-sm);
  button {
    color: var(--#{$prefix}white-text);
    text-decoration: underline;
    text-decoration-color: var(--#{$prefix}secondary);
    background-color: transparent;
    text-underline-offset: .25em;
  }
}

// **********************************************************
// 
.gdpr-privacy-banner .gdpr-privacy-content-wrapper .gdpr-privacy-settings .settings-column .settings-title label {
  padding-right: 5rem;
  line-height: 1.1;
}